<template>
  <div class="course-detail">
    <van-nav-bar class="course-detail-title" fixed left-arrow @click-left="onBack" style="background: none;">
      <!--            <template #right @click-right="userKefu">-->
      <!--                <van-icon name="share-o" @click="userKefu"/>-->
      <!--            </template>-->
    </van-nav-bar>

    <div class="banner-course-detail" ref="attrRef">
      <video-player
          class="video-player vjs-custom-skin"
          ref="videoPlayer"
          name="videoPlayer"
          id="video"
          :playsinline="true"
          :options="playerOptions"
          @play="onPlayerPlay($event)"
          @pause="onPlayerPause($event)"
          @timeupdate="onPlayerTimeupdate($event)"
          @loadeddata="onPlayerLoadeddata($event)"
      ></video-player>
    </div>
    <!-- <div class="course-title">
      <van-tag type="warning">直播+录播</van-tag>2022年中药学综合知识与技能（精品班）
      <div class="course-price">￥980.00</div>
    </div> -->
    <!-- <van-cell center title="2022年中药学综合知识与技能（精品班）" class="course-service" @click="serviceShow = true">
      <template #right-icon>
        <van-tag round type="warning"><van-icon name="share" size="2rem" />分享</van-tag>
      </template>
    </van-cell> -->
    <van-tabs v-model:active="active" color="#1989fa" swipeable class="shop_detail" :style="contentStyleObj1">
      <van-tab title="目录">
        <div v-if="courseList.length > 0">
          <van-cell center class="practice" title="班次配套习题" v-if="testPaperStatus == true">
            <template #icon>
              <van-icon name="records" size="24"/>
            </template>
            <template #right-icon>
              <van-button round color="#ff5722" size="small" @click="onCommodity">点击进入</van-button>
            </template>
          </van-cell>
          <div class="courseList" v-for="(item,index) in courseList" :key="index">
            <van-collapse v-model="activeNames" @change="onCollChange">
              <van-collapse-item :name="index">
                <template #title>
                  <div class="courseListTitle">
                    <van-tag round color="#f5f5f5" text-color="#323233" size="medium">
                      {{ item.cate_name }}
                    </van-tag>
                  </div>
                </template>
                <div v-for="(itemText,index2) in item.data" :key="index2">
                  <!-- 解锁的视频 -->
                  <van-cell center :data-id="itemText.id" @click="changeVideo(itemText.id)">
                    <template #title>
                      <span
                          :style="{'color': (itemText.id==courseWareId)?'#1989fa':'#323233'}">{{ itemText.video_title }}</span>
                    </template>
                    <template #label>
                      <span
                          :style="{'color': (itemText.id==courseWareId)?'#1989fa':'#969799'}">时长：{{ itemText.time }}丨{{ itemText.studyDuration ? '已学习：' + itemText.studyDuration : '暂未学习' }}</span>
                    </template>
                    <template #right-icon>
                      <span class="iconfont " v-if="(itemText.id==courseWareId)"
                            :class="[videoState==true?'icon-zhengzaibofang':'icon-bofang']"></span>
                      <span class="iconfont icon-bofang2" v-else></span>
                    </template>
                  </van-cell>
                  <!-- 带锁的视频 -->
                  <!--                                    <van-cell center :title="itemText.video_title" v-if="itemText.audition==1" @click="Dialog">-->
                  <!--                                        <template #label>-->
                  <!--                                            <span>时长：{{itemText.video_duration}}丨{{itemText.teacher_id}}</span>-->
                  <!--                                        </template>-->
                  <!--                                        <template #right-icon>-->
                  <!--                                            <span class="iconfont icon-suo1"></span>-->
                  <!--                                        </template>-->
                  <!--                                    </van-cell>-->
                </div>
              </van-collapse-item>
            </van-collapse>
          </div>
        </div>
        <div v-else>
          <van-empty image-size="30rem" description="课程暂未更新"/>
        </div>
      </van-tab>
      <van-tab title="答疑">
        <div v-if="repliedList.length > 0">
          <div class="courseList1" v-for="(item,index) in repliedList" :key="index" @click="questionDetail(item.id)">
            <van-cell center class="answering">
              <template #icon>
                <van-icon name="https://fastly.jsdelivr.net/npm/@vant/assets/icon-demo.png"/>
              </template>
              <template #title>
                <span class="custom-title">{{ item.username ? item.username : '无' }}</span><br>
                <van-tag color="#f8f8f8" text-color="#969799">{{ item.create_time ? item.create_time : '无' }} -
                  来自{{ item.source }}
                </van-tag>
              </template>
              <template #label>
                <span>{{ item.title }}</span>
              </template>
            </van-cell>
            <div class="reply_tag" v-if="item.children">
              <van-tag round color="#1989fa" text-color="#ffffff">答</van-tag>
              <span
                  class="reply_text">{{ item.children ? '亲爱的同学，很高兴能为您提供帮助，' + item.children.title : '无' }}</span>
            </div>
          </div>
        </div>
        <div v-else>
          <van-empty image-size="30rem" description="暂无答疑问题"/>
        </div>
        <div class="answering-question">
          <van-button round icon="question-o" color="#1989fa" class="question-ask" @click="askQuestion">提问问题
          </van-button>
        </div>
      </van-tab>
      <van-tab title="笔记">
        <div v-if="NoteList.length > 0">
          <div class="courseList1" v-for="(item,index) in NoteList" :key="index">
            <van-cell center class="note">
              <template #icon>
                <van-icon name="https://fastly.jsdelivr.net/npm/@vant/assets/icon-demo.png"/>
              </template>
              <template #title>
                <span class="custom-title"
                      @click="myNoteeditGo(item.id)">{{ item.username ? item.username : '无' }}</span><br>
                <van-tag type="primary" @click="videoDuration(item.video_duration2)">
                  视频位置：{{ item.video_duration }}
                </van-tag>
              </template>
              <template #label>
                <span>{{ item.title }}</span>
              </template>
            </van-cell>
            <van-button round color="#f1f1f1" text-color="#323233" class="course-question-tag1">
              <van-tag round color="#a9a9a9" text-color="#ffffff">{{ item.stage_title }}</van-tag>
              {{ item.video_title }}
            </van-button>
            <div v-if="item.is_type == 1">
              <div class="course-question-tag-time1">
                <van-tag color="#ffffff" text-color="#969799" size="medium" @click="delNode(item.id)">
                  <van-icon name="delete-o"/>
                  删除
                </van-tag>
                <van-tag round color="#ffffff" text-color="#1989fa" size="medium">
                  <van-icon name="eye-o"/>
                  {{ item.type }}
                </van-tag>
                <van-tag round color="#1989fa" text-color="#ffffff" size="medium" @click="myNoteeditGo(item.id)">
                  <van-icon color="#ffffff" name="edit"/>
                  修改
                </van-tag>
              </div>
            </div>
            <div v-else>
              <van-tag round color="#ffffff" text-color="#1989fa" size="medium">
                <van-icon name="eye-o"/>
                {{ item.type }}
              </van-tag>
            </div>
          </div>
        </div>
        <div v-else>
          <van-empty image-size="30rem" description="暂无提交的笔记内容"/>
        </div>
        <div class="answering-question">
          <van-button round icon="edit" color="#1989fa" class="edit" @click="addNote">添加笔记</van-button>
        </div>
      </van-tab>
      <van-tab title="讲义">
        <van-image
            width="100%"
            height="auto"
            lazy-load
            :src="handout"
        />
        <div class="answering-question">
          <van-button round icon="down" color="#1989fa" class="edit" @click="onDown">讲义下载</van-button>
        </div>
        <!-- <van-empty image-size="30rem" description="该课程暂无讲义" /> -->
      </van-tab>
    </van-tabs>
  </div>
</template>

<script>


import {
  getCommodityCourseCoursewareList,
  getCoursewareInfo,
  coursewareDuration,
  onShareUrl,
  onCourseList, repliedPost, repliedList, noteList, noteAdd, noteDel, coursewareDurationInfo
} from '@/request/api'
import Qs from 'qs'
import wx from 'weixin-js-sdk';
import {Toast} from "vant";

export default {
  metaInfo() {
    return {
      title: this.title, //设置页面title
      meta: [{                 //设置页面meta
        name: 'keyWords',
        content: this.title
      },
        {
          name: "description",
          content: this.title,
        },
      ]
    }
  },
  data() {
    return {
      activeNames: [0],
      isShowBo: true,
      banner: [],
      current: 0,
      active: '',
      serviceShow: false,
      teacherShow: false,
      status: '',
      contentStyleObj: {
        height: '',
        width: ''
      },
      contentStyleObj1: {
        top: ''
      },
      msg: '',
      poster: 'https://cdnoss.ksyt.com.cn/school/20220909/videoImg.png', //封面
      videoUrl: 'https://video.ksyt.com.cn/317197d05a8d71ed804e0675a0ec0102/276e8a4ce47d4357bb675f9de2152db3-438ab8684c7676c335845ac01f5bcdc8-ld.m3u8',
      videoState: false, // 视频播放状态
      // 学时
      studyTime: {
        currentTime: 0, // 当前已学时长
        duration: 0 // 总时长
      },
      timer: {}, // 定时器
      pauseTimer: {}, // 暂停定时器
      courseList2: [],
      courseList: [],
      keyIndex: '',
      jssdk: [],
      title: '',
      photopath: 'https://cdnoss.ksyt.com.cn/school/51e7b00a0c42d29e1c7ce182ca20634.jpg',
      website: [],
      commodityId: '',
      courseId: '',
      cate_id: '',
      courseWareId: '',
      courseWareCourseId: '',
      NoteDuration: 0, //笔记课程时长,
      logo: '',
      coursewareType: [],
      videoDetail: '',
      showProgress: true,
      playlength: 0,
      video_real_time: 0,
      initial_time: 0,
      handout: '',
      repliedList: [],
      NoteList: [],
      testPaperStatus: false,
      currentTime: 1,
      playerOptions: {
        playbackRates: [0.5, 1.0, 1.5, 2.0], //播放速度
        autoplay: false, //如果true,浏览器准备好时开始回放。
        muted: false, // 默认情况下将会消除任何音频。
        loop: false, // 导致视频一结束就重新开始。
        preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        language: 'zh-CN',
        aspectRatio: '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        sources: [{
          type: "video/mp4",
          // type: 'application/x-mpegURL',
          src: "" //url地址
        }],
        poster: "", //你的封面地址
        // width: document.documentElement.clientWidth,
        notSupportedMessage: '此视频暂无法播放，请稍后再试', //允许覆盖Video.js无法播放媒体源时显示的默认信息。
        controlBar: {
          timeDivider: false, // 时间分割线
          durationDisplay: false, // 时长显示
          remainingTimeDisplay: false, // 剩下时间
          currentTimeDisplay: true, // 当前时间
          volumeControl: false, // 声音控制键
          mutedControl: false, // 声音控制键
          playToggle: true, // 暂停和播放键
          progressControl: true, // 进度条
          fullscreenToggle: true  //全屏按钮
        }
      },
      hand_out_list: [],
      playStatus: true,
    };
  },
  mounted() {
    //操作节点
    this.contentStyleObj.height = this.$refs.attrRef.offsetWidth * 9 / 16 + 'px ' + '!important';
    this.contentStyleObj.width = window.getComputedStyle(this.$refs.attrRef).width + ' ' + '!important';
    // top = this.contentStyleObj.height;
    this.contentStyleObj1.top = this.contentStyleObj.height;


    this.src = "https://video.ksyt.com.cn/e54c29ac9e974a95a195072432aaefd3/f90c485daf5d4dd6a9cac448d785a01f-1347ea9bd7105c6d4ed30c8645e1df12-ld.mp4";

    // // 监听视频播放
    // this.$refs.videoPlayer.player.addEventListener("play", () => {
    //     console.log("video is playing");
    //     console.log(this.$refs.videoPlayer.player.src);
    //     this.openTimer();
    //     this.videoState = true;
    //     this.isShowBo = false;

    // });
    // // 监听视频暂停
    // this.$refs.videoPlayer.player.addEventListener("pause", () => {
    //     console.log("video is stop");
    //     this.closeTimer();
    //     this.videoState = false;
    //     this.isShowBo = false;

    // });


    // 监听 resize 方法
    window.addEventListener("resize", this.renderResize, false)

  },
  beforeDestroy() {
    // 移除监听
    window.removeEventListener("resize", this.renderResize, false)
  },
  methods: {

    renderResize() {
      var aa = window.matchMedia("(orientation: portrait)").matches
      console.log(aa + '123')
      if (aa) {
        console.log('hengping')
        this.$refs.videoPlayer.player.requestFullscreen()
      }
      var portrait = window.matchMedia("(orientation: portrait)").matches ? '竖屏' : '横屏'
      setTimeout(() => {
        var height = window.innerHeight || document.documentElement.clientHeight;
        console.log(
            '【' + portrait + 'height:',
            height, '】'
        )
      }, 500);
    },

    // 开启定时器
    openTimer() {
      this.timer = setInterval(() => {
        this.$emit("videoStudyTime", this.studyTime);
        // console.log(this.studyTime)
      }, 5000);
    },
    // 关闭定时器
    closeTimer() {
      clearInterval(this.timer);
      this.$emit("videoStudyTime", this.studyTime);
    },
    // 开启暂停定时器
    openPauseTimer() {
      this.pauseTimer = setInterval(() => {
        this.hintOperate();
      }, 6000);
    },
    // 关闭暂停定时器
    closePauseTimer() {
      clearInterval(this.pauseTimer);
    },
    // 提示操作
    hintOperate() {

      this.operateVideo();
      this.$dialog.confirm({
        title: '课程提示',
        message: '请点击确认继续学习',
        confirmButtonColor: '#1989fa'
      }).then(() => {
        // this.$refs.videoPlayer.player.currentTime = this.videoData.currentTime;
        this.operateVideo();
        this.openPauseTimer();
      }).catch(() => {
        console.log('点击了取消')
      })
    },
    // 获取当前播放位置
    timeupdate(e) {
      // console.log(e);
      this.studyTime.currentTime = e.target.currentTime;
      this.studyTime.duration = e.target.duration ? e.target.duration : 0;
      // console.log(this.studyTime.duration);
    },

    // 操作视频播放、暂停
    operateVideo(id, src, key, title, img) {
      if ((key == this.keyIndex && id == this.courseWareId)) {
        return false;
      }
      this.$refs.videoPlayer.player.src = src;
      this.courseWareId = id;
      this.videoState = true;
      this.keyIndex = key;
      this.title = title;
      this.photopath = img;
      // console.log(e.currentTarget.data.src);

      if (!this.src) {
        this.$dialog.alert({
          // title:'付费提示',
          message: '暂无视频资源\n请查看其他视频',
          confirmButtonColor: '#1989fa'
        }).then(() => {
          console.log('点击了确认')
        }).catch(() => {
          console.log('点击了取消')
        })
        return false;
      }
      if (this.$refs.videoPlayer.player.paused) {
        this.$refs.videoPlayer.player.play();
      } else {
        this.$refs.videoPlayer.player.pause();
      }
    },

    //（确认、取消）的弹出框
    Dialog() {
      this.$dialog.confirm({
        title: '付费提示',
        message: '当前为免费试听模式，\n付费后即可解锁听课权限',
        confirmButtonColor: '#1989fa'
      }).then(() => {
        console.log('点击了确认')
      }).catch(() => {
        console.log('点击了取消')
      })
    },


    handleClickSearch() {
      this.$router.push('/course/searchPopup')
      // 修改vuex中的 isShowPopupShow 值为true
      // this.$store.commit("changeIsShowPopupShow", true)
    },
    onChange(index) {
      this.current = index;
    },
    handelService() {
      this.$router.push('/course/searchPopup')
      // 修改vuex中的 isShowPopupShow 值为true
      // this.$store.commit("changeIsShowPopupShow", true)
    },
    handelFreeAudition() {
      this.$router.push('/freeAudition')
      // 修改vuex中的 isShowPopupShow 值为true
      // this.$store.commit("changeIsShowPopupShow", true)
    },


    onBack() {
      // this.$router.go(-1)
      // this.$router.back(-1)
      // history.back(-1);
      this.$router.push({name: 'LearningList', params: {id: this.commodityId}});
    },
    onShare() {
      history.back();
    },
    userKefu() {

    },
    getCourseWare() {
      let that = this;
      let id = that.courseId;
      let commodityId = that.commodityId;
      // let id = 49;
      getCommodityCourseCoursewareList({id, commodityId})
          .then(res => {
            if (res.data.code == 1) {
              that.courseList = res.data.data.data;
              that.title = res.data.data.title;
              that.logo = res.data.data.logo
              that.coursewareType = res.data.data.courseware
              that.videoDetail = res.data.data.desc
              that.showProgress = res.data.data.type
              let courseList = res.data.data.data;
              let courseWareCourseId = that.courseWareCourseId;
              if (!courseWareCourseId) {
                res.data.data.data.forEach(function (item, index) {
                  if (index == 0) {
                    item.data.forEach(function (item2, index2) {
                      if (index2 == 0) {
                        that.getVideo(item2.id);
                      }
                    })
                  }
                })
              } else {
                for (let item in this.courseList) {
                  for (let item2 in courseList[item]['data']) {
                    if (courseList[item]['data'][item2].id == courseWareCourseId) {
                      that.getVideo(courseWareCourseId);
                      that.activeNames = [Number(item)];
                    }
                  }
                }
              }
            }
            // this.$store.state.cart.forEach(item => {
            //     if (item.flag) {
            //         this.$store.state.cart.splice(item, 1)
            //         this.result.splice(item.id, 1)
            //     }
            // })
            // console.log("success!!!!!111");
            // console.log(res);
            // this.courseList = res.data.data.banner
            // console.log(this.banner);
          })
          .catch(err => {
            console.log("error");
            console.log(err);
          })
    },
    onCollChange(event) {
      this.setData({
        activeNames: event.detail,
      });
    },
    changeVideo(id) {
      console.log(id);
      let coursewarePlayId = this.courseWareId; //正在播放课件id
      console.log(coursewarePlayId);
      if (coursewarePlayId == id) {
        this.$refs.videoPlayer.player.play();
        return;
      }
      // this.videoContext = wx.createVideoContext('videoPlayer');
      // let rateTile = this.data.playbackRate;
      // this.videoContext.playbackRate(Number(rateTile));
      this.getVideo(id);
    },
    getVideo(id) {
      let that = this;
      let commodityId = this.commodityId
      getCoursewareInfo({id, type: 2, commodityId})
          .then(res => {
            if (res.data.code == 1) {
              that.playerOptions['sources'][0]['src'] = res.data.data.url;
              that.studyTime.currentTime = res.data.data.video_duration
              that.studyTime.duration = res.data.data.video_duration_time
              that.currentTime = 1;
              that.courseWareId = id;
              -``
              that.videoState = true;
              // that.keyIndex = key;
              that.title = res.data.data.video_title;
              that.handout = res.data.data.videoHandout;
              that.video_real_time = 0;
              // if (that.$refs.videoPlayer.player.paused) {
              //     that.$refs.videoPlayer.player.play();
              // } else {
              //     that.$refs.videoPlayer.player.pause();
              // }
              that.testPaperStatus = res.data.data.testPaperStatus
              that.cate_id = res.data.data.cate_id
              that.onReplied();
              that.onNote();
            }
          })
          .catch(err => {
            console.log("error");
            console.log(err);
          })
    },
    askQuestion() {
      this.$router.push({
        name: 'MyAskQuestion',
        params: {
          commodityId: this.commodityId,
          courseId: this.courseId,
          courseWareId: this.courseWareId,
          title: this.title
        },
        query: {
          commodityId: this.commodityId,
          courseId: this.courseId,
          courseWareId: this.courseWareId,
          title: this.title
        }
      });
    },
    onReplied() {
      let that = this;
      let coursewareId = that.courseWareId;
      repliedList({coursewareId}).then(res => {
        if (res.data.status == 1) {
          that.repliedList = res.data.data
        }
      })
    },
    questionDetail(id) {
      this.$router.push({
        name: 'courseQuestionDetail',
        params: {
          commodityId: this.commodityId,
          courseId: this.courseId,
          courseWareId: this.courseWareId,
          id
        },
        query: {
          commodityId: this.commodityId,
          courseId: this.courseId,
          courseWareId: this.courseWareId,
          id
        }
      });
    },
    onNote() {
      let that = this;
      let coursewareId = that.courseWareId;
      noteList({coursewareId}).then(res => {
        if (res.data.status == 1) {
          that.NoteList = res.data.data
        }
      })
    },
    addNote() {
      this.$router.push({
        name: 'MyNoteAdd',
        params: {
          commodityId: this.commodityId,
          courseId: this.courseId,
          courseWareId: this.courseWareId,
          title: this.title,
          currentTime: 0
        },
        query: {
          commodityId: this.commodityId,
          courseId: this.courseId,
          courseWareId: this.courseWareId,
          title: this.title,
          currentTime: 0
        }
      });
    },
    delNode(id) {
      let that = this;
      let coursewareId = that.courseWareId;
      noteDel({id}).then(res => {
        if (res.data.status == 1) {
          Toast.success('删除成功');
          that.onNote();
        }
      })
    },
    //修改
    myNoteeditGo(id) {
      this.$router.push({
        name: 'MyNoteAdd',
        params: {
          id,
          commodityId: this.commodityId,
          courseId: this.courseId,
          courseWareId: this.courseWareId,
          title: this.title,
          currentTime: 0
        },
        query: {
          id,
          commodityId: this.commodityId,
          courseId: this.courseId,
          courseWareId: this.courseWareId,
          title: this.title,
          currentTime: 0
        }
      });
    },
    //详情 开发中
    noteDetail() {
      this.$router.push({
        name: 'MyNoteAdd',
        params: {
          commodityId: this.commodityId,
          courseId: this.courseId,
          courseWareId: this.courseWareId,
          title: this.title,
          currentTime: 0
        },
        query: {
          commodityId: this.commodityId,
          courseId: this.courseId,
          courseWareId: this.courseWareId,
          title: this.title,
          currentTime: 0
        }
      });
    },
    //配套习题
    onCommodity() {
      console.log(123);
      // let id = this.data.commodityId;
      // let cate_id = this.data.info.cate_id;
      // let course_id = this.data.info.course_id;
      localStorage.setItem('courseDetailParams', JSON.stringify({
        commodityId: this.commodityId,
        courseId: this.courseId,
        courseWareCourseId: this.courseWareCourseId
      }))
      //随堂练习
      this.$router.push({
        name: 'CoursePractice',
        query: {
          course_id: this.courseId,
          id: this.commodityId,
          cate_id: this.cate_id,
          coursewareId: this.courseWareId
        }
      })
    },
    onDown() {
      localStorage.setItem('courseDetailParams', JSON.stringify({
        commodityId: this.commodityId,
        courseId: this.courseId,
        courseWareCourseId: this.courseWareCourseId
      }))
      this.$router.push({
        name: 'CourseData',
        query: {
          courseId: this.courseId
        }
      })
    },
    //播放事件
    // 播放回调
    onPlayerPlay(player) {
      if (this.video_real_time == 0) {
        // alert('播放');
        // alert(this.video_real_time);
        // alert(this.studyTime.currentTime);
        // console.log('播放回调');
        // console.log( player);
        player.currentTime(this.studyTime.currentTime)
      }
    },

    // 暂停回调
    onPlayerPause(player) {
      console.log("player pause!", player);
    },

    // 视频播完回调
    onPlayerEnded($event) {
      console.log(player);
    },
    // DOM元素上的readyState更改导致播放停止
    onPlayerWaiting(player) {
      if (this.video_real_time == 0) {
        // alert('视频加载');
        // alert(this.video_real_time);
        // alert(this.studyTime.currentTime);
        // console.log('播放回调');
        // console.log( player);
        player.currentTime(this.studyTime.currentTime)
      }
      // let time = localStorage.getItem("cacheTime")
      // if(player.cache_.currentTime - Number(time) > 0.1){
      //     this.current = Number(time)
      //     this.playerReadied(player)
      // }else{
      //     this.current = player.cache_.currentTime
      // }
    },

    // 已开始播放回调
    onPlayerPlaying($event) {
      // console.log(player)
    },

    // 当播放器在当前播放位置下载数据时触发
    onPlayerLoadeddata($event) {
      console.log('当播放器在当前播放位置下载数据时触发', $event)
      // alert('当播放器在当前播放位置下载数据时触发');
      if (this.video_real_time == 0) {
        $event.currentTime(this.studyTime.currentTime)
      }
      $event.on('fullscreenchange', () => {
        // player.isFullscreen_就是全屏的状态，true为全屏，false为不全屏
        // 在这里处理你的需求
        console.log($event.isFullscreen_)
      })
    },

    // // 当前播放位置发生变化时触发。
    onPlayerTimeupdate(player) {
      var currentTime = player.currentTime();
      var duration = this.studyTime.duration; //总时长
      var courseWareId = this.courseWareId;
      var courseId = this.courseId;//课程ID
      var commodityId = this.commodityId;//商品ID
      var time = this.currentTime;
      if (this.video_real_time == 0) {
        var jump_time = parseInt(duration) + parseInt(this.video_real_time);
      } else {
        var jump_time = parseInt(this.video_real_time)
      }
      if (!this.showProgress) {
        //禁止快进
        if (currentTime > jump_time && (currentTime - jump_time > 3)) {
          player.currentTime(this.video_real_time);
          Toast.fail('未完整看完该视频，不能快进');
          return false;
        }
      }
      // console.log(currentTime);
      // console.log(time);
      var that = this;
      that.video_real_time = currentTime;
      this.studyTime.currentTime = currentTime;
      if (currentTime > time) {
        that.currentTime = (currentTime + 10)
        //更新记录
        coursewareDurationInfo({
          id: courseWareId,
          duration: currentTime,
          commodity_id: commodityId,
          course_id: courseId,
          ceshi: 123
        })
            .then(res => {

            })
            .catch(err => {
              console.log("error");
              console.log(err);
              // Toast.fail('未完整看完该视频，不能快进');
            })
      }
      return false;
    },

    //媒体的readyState为HAVE_FUTURE_DATA或更高
    onPlayerCanplay(player) {
      // console.log('player Canplay!', player)
    },

    //媒体的readyState为HAVE_ENOUGH_DATA或更高。这意味着可以在不缓冲的情况下播放整个媒体文件。
    onPlayerCanplaythrough(player) {
      // console.log('player Canplaythrough!', player)
    },

    //播放状态改变回调
    playerStateChanged(playerCurrentState) {
      // console.log('player current update state', playerCurrentState)
    },

    //将侦听器绑定到组件的就绪状态。与事件监听器的不同之处在于，如果ready事件已经发生，它将立即触发该函数。。
    playerReadied(player) {
      // console.log('example player 1 readied', player);
      // player.currentTime(this.studyTime.currentTime)
      // alert(this.studyTime.currentTime);
      // console.log(player);
      // player.currentTime(1000);
    },
    currentTimeUpdate(player) {
      console.log('currentTimeUpdate');
    },
    getVideoHandOut(id) {
      let that = this;
      getCoursewareHandout({coursewareId: id})
          .then(res => {
            if (res.data.code == 1) {
              let hand_out_list = res.data.list;
              for (let i = 0; i < hand_out_list.length; i++) {
                let datas = hand_out_list[i].content;
                hand_out_list[i].pause_type = false;
                datas = datas.replace(/\<img/gi, '<img class="rich-img"');
                datas = datas.replace(/\<table/gi, '<table class="rich-table"');
                datas = datas.replace(/\<th/gi, '<th class="rich-th"');
                hand_out_list[i].content = datas.replace(/\<td/gi, '<td class="rich-td"');
              }
              that.hand_out_list = hand_out_list;
              that.handoutPlayType()
            }
          })
          .catch(err => {
            console.log("error");
            console.log(err);
          })
    },
    handoutPlayType() {
      let handout_list = this.hand_out_list;
      let currentTime = this.video_real_time;
      let playStatus = this.playStatus;
      let len = handout_list.length;
      for (let i = 0; i < len; i++) {
        let j = i + 1;
        let info = handout_list[i];
        if (j >= len) {
          if (currentTime >= info.start_time) {
            if (playStatus) {
              handout_list[i].play_status = true;
            } else {
              handout_list[i].play_status = false;
            }
            handout_list[i].play_type = true;
          } else {
            handout_list[i].play_type = false;
            handout_list[i].play_status = false;
          }
        } else {
          let info2 = handout_list[i + 1];
          if (currentTime >= info.start_time && currentTime <= info2.start_time) {
            if (playStatus) {
              handout_list[i].play_status = true;
            } else {
              handout_list[i].play_status = false;
            }
            handout_list[i].play_type = true;
          } else {
            handout_list[i].play_status = false;
            handout_list[i].play_type = false;
          }
        }
      }
      this.hand_out_list = handout_list;
    },
  },
  created() {

    if (this.$route.params.id) {
      this.courseWareCourseId = this.$route.params.coursewareId;//上次记录
      this.courseId = this.$route.params.id;//课程ID
      this.commodityId = this.$route.params.commodityId;//商品ID
    } else {
      let params = localStorage.getItem('courseDetailParams');
      console.log(params);
      this.courseWareCourseId = JSON.parse(params).courseWareCourseId;//上次记录
      this.courseId = JSON.parse(params).courseId;//课程ID
      this.commodityId = JSON.parse(params).commodityId
    }
    this.getCourseWare();
    this.title = this.getBranchCompany()
    // onCourseDetail(Qs.stringify({subject_id: this.$route.query.subjectId, stageid: this.$route.query.stageId}))
    //     .then(res => {
    //         if (res.data.code == 1) {
    //             this.courseList = res.data.data.data;
    //             this.courseList2 = res.data.data.data1;
    //             this.website = res.data.data.website;
    //             let courseList = res.data.data.data
    //             for (let item in this.courseList) {
    //                 for (let item2 in courseList[item]['arr']) {
    //                     if (courseList[item]['arr'][item2].audition == 2) {
    //                         if(courseList[item]['arr'][item2].url) {
    //                             this.operateVideo(courseList[item]['arr'][item2].id,courseList[item]['arr'][item2].url,1,courseList[item]['arr'][item2].video_title,courseList[item]['arr'][item2].img);
    //                             throw Error();
    //                         }
    //                         // throw Error();
    //                     }
    //                 }
    //             }
    //         }
    //         // this.$store.state.cart.forEach(item => {
    //         //     if (item.flag) {
    //         //         this.$store.state.cart.splice(item, 1)
    //         //         this.result.splice(item.id, 1)
    //         //     }
    //         // })
    //         // console.log("success!!!!!111");
    //         // console.log(res);
    //         // this.courseList = res.data.data.banner
    //         // console.log(this.banner);
    //     })
    //     .catch(err => {
    //         console.log("error");
    //         console.log(err);
    //     })
    // onShareUrl(Qs.stringify({url: location.href.split('#')[0]}))
    //     .then(res => {
    //         if (res.data.code == 1) {
    //             wx.config({
    //                 debug: false,// 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
    //                 appId: res.data.data.sdk.appId,         // 必填，公众号的唯一标识，填自己的！
    //                 timestamp: res.data.data.sdk.timestamp, // 必填，生成签名的时间戳，刚才接口拿到的数据
    //                 nonceStr: res.data.data.sdk.nonceStr,   // 必填，生成签名的随机串
    //                 signature: res.data.data.sdk.signature, // 必填，签名，见附录1
    //                 jsApiList: [
    //                     'onMenuShareTimeline',
    //                     'onMenuShareAppMessage'
    //                 ]
    //             })
    //             let that = this;
    //             console.log(this.title);
    //             wx.ready(function () {
    //                 //分享到朋友圈
    //                 wx.onMenuShareTimeline({
    //                     title:  that.title,   // 分享时的标题
    //                     link: location.href,     // 分享时的链接
    //                     desc: '高清视频，同步字幕，名师精讲，像看电影一样的学习体验，助你轻松备考！',
    //                     imgUrl: that.photopath,    // 分享时的图标
    //                     success: function () {
    //                         console.log("分享成功");
    //                     },
    //                     cancel: function () {
    //                         console.log("取消分享");
    //                     }
    //                 });
    //                 //分享给朋友
    //                 wx.onMenuShareAppMessage({
    //                     title:  that.title,
    //                     // desc: '这件商品终于优惠了，每件只需'+pri_fx+'元',
    //                     desc: '高清视频，同步字幕，名师精讲，像看电影一样的学习体验，助你轻松备考！',
    //                     link: location.href,
    //                     imgUrl: that.photopath,
    //                     type: '',
    //                     dataUrl: '',
    //                     success: function () {
    //                         console.log("分享成功");
    //                     },
    //                     cancel: function () {
    //                         console.log("取消分享");
    //                     }
    //                 });
    //             });
    //             wx.error(function(res){
    //                 console.log(res);
    //                 // config信息验证失败会执行 error 函数，如签名过期导致验证失败，具体错误信息可以打开 config 的debug模式查看，也可以在返回的 res 参数中查看，对于 SPA 可以在这里更新签名。
    //             })
    //         }
    //     })
    //     .catch(err => {
    //         console.log("error");
    //         console.log(err);
    //     })
  },
  components: {},
  watch: {
    // 监听操作
    videoData(val, oldVal) {
      // const {currentTime, duration} = val;
      // if (currentTime && duration && currentTime < duration) {
      //     this.hintOperate();
      // }
    }
  }

}
</script>
<style lang="less">
@import '~@/assets/styles/common.less';
@import '~@/assets/iconfont/iconfont.css';


// body {
//   overflow: hidden;
// }

.course-detail {
  background-color: #ffffff;
}

.banner-course-detail {
  position: fixed;
  width: 100%;
  z-index: 99;
}

.popup-shadow {
  width: 100%;
  height: 100%;
  position: absolute;
  // z-index: 99999;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, .5);
  z-index: 20;
}

// .slide-enter, .slide-leave-to {
//   // 过渡之前的样式写在这里
//    right: -100%;
// }
// .slide-enter-active,.slide-leave-active  {
//   // 过渡属性写在这里
//   transition: all .3s;
// }
// .slide-enter-to, .slide-leave {
//   // 过渡之后的样式写在这里
//    right: 0;
// }
.shoplist1 {
  padding: 0.8rem;
  background-color: #ffffff;
}

// .course-title {
//   padding: 2.4rem 1.5rem 1.5rem 1.5rem;
//   font-size: 2.4rem;
//   line-height: 3.6rem;
//   font-weight: 600;
//   color: #333;
// }

// .course-title .van-tag {
//   padding: 0.3rem 0.6rem;
//   position: relative;
//   bottom: 0.2rem;
//   margin-right: 0.6rem;
// }

// .course-title .course-price {
//   padding-top: 0.5rem;
//   color: #ee0a24;
//   font-weight: 600;
//   font-size: 2.4rem;
// }

.course-service {
  padding: 0 1.5rem;
}

.course-service .van-tag {
  margin-right: 0.4rem;
}

.course-service .weapp-nav-icon {
  font-size: 3rem;
}

.service-popup {
  height: 50% !important;;
}

.service-popup .content2 {
  padding: 1.2rem 0;
}

.service-popup .content2 .van-cell {
  padding: 0 1.5rem;
  margin: 2rem 0;
}

.service-popup .content2 .van-cell::after {
  border-width: 0;
}

.service-popup .content2 .van-cell .van-icon {
  font-size: 4rem;
  margin-right: 0.6rem;
}

.service-popup .content2 .van-cell .van-cell__title {
  font-size: 2rem;
}

.service-popup .content2 .van-cell .van-cell__label {
  font-size: 1.6rem;
}


.teacher-popup {
  height: 50% !important;;
}

.teacher-popup .content2 {
  padding: 1.2rem 0;
}

.teacher-popup .content2 .van-cell {
  padding: 0 1.5rem;
  margin: 2rem 0;
}

.teacher-popup .content2 .van-cell::after {
  border-width: 0;
}

.teacher-popup .content2 .van-cell .van-icon {
  font-size: 4rem;
  margin-right: 0.6rem;
  color: #666666;
}

.teacher-popup .content2 .van-cell .van-cell__title {
  font-size: 2rem;
}

.teacher-popup .content2 .van-cell .van-cell__label {
  font-size: 2rem;
  line-height: 4rem;
}

.teacher-popup .content2 .pho-wx {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}

.shop_detail {
  background-color: #f5f5f5;
  position: fixed;
  width: 100%;
  bottom: 0;
  overflow-y: scroll;
  overflow-x: hidden;
}

.shop_detail .van-tabs__wrap {
  position: fixed;
  z-index: 100;
  width: 100%;
}

.shop_detail .van-tabs__content {
  padding-bottom: 6rem;
  padding-top: 6.5rem;
}

.shop_detail .courseList {
  margin: 0.5rem 1rem 1rem 1rem;
  border-radius: 1rem;
  background-color: #ffffff;
  padding: 0;
}

.shop_detail .courseList .courseListTitle {
  background-color: #ffffff;
  font-size: 2rem;
  line-height: 3rem;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
}

.shop_detail .courseList .courseListTitle .van-tag {
  font-size: 2rem !important;
  line-height: 3rem !important;
  padding: 1rem 1.5rem !important;
  font-weight: 600;
  text-overflow: clip;
  overflow: hidden;
  white-space: nowrap;
}

.shop_detail .courseList .van-cell {
  border-radius: 1rem !important;
  padding: 1rem 1.5rem;
}

.van-collapse .van-collapse-item .van-collapse-item__wrapper {
  border-radius: 1rem;
}

.van-icon-like:before {
  color: #ee0a24 !important;
}

// .shop_detail .evaluation {
//   margin: 1rem;
//   border-radius: 2rem;
//   background-color: #ffffff;
// }

// .shop_detail .evaluation .van-cell {
//   border-radius: 1rem;
//   padding: 1.5rem 1rem;
// }

// .shop_detail .evaluation .van-cell .van-icon {
//   float: left;
//   top: 0;
//   margin: 0.3rem 0.6rem;
// }

// .shop_detail .evaluation .van-cell .van-icon .van-icon__image {
//   border-radius: 100%;
// }

// .shop_detail .evaluation .van-cell .van-cell__title {
//   color: #333;
//   font-size: 1.8rem;
// }

// .shop_detail .evaluation .van-cell .evaluationContent {
//   color: #333;
//   font-size: 1.8rem;
//   line-height: 3.2rem;
// }

// .shop_detail .evaluation .title-1 {
//   height: 4rem !important;
// }

// .shop_detail .evaluation .title-1 .van-cell__title {
//   color: #333;
//   font-size: 2.2rem;
//   font-weight: 600;
//   padding: 0 0.5rem;
// }

.shop_detail .title-2 {
  height: 4rem !important;
}

.shop_detail .title-2 .van-cell__title {
  color: #333;
  font-size: 2.2rem;
  font-weight: 600;
  padding: 0 1rem !important;
}

.course-detail-title {
  position: fixed;
  z-index: 100;
  height: 5rem !important;
}

.course-detail-title .van-nav-bar__content .van-icon {
  color: #ffffff;
}

.course-detail-title .van-nav-bar__content .van-nav-bar__left .van-icon {
  font-size: 3rem;
  padding: 0.5rem;
  border-radius: 5rem;
  background-color: rgba(0, 0, 0, 0.4);
  margin-left: 0.5rem;
}

.course-detail .van-icon-arrow-left:before {
  color: #ffffff;
  opacity: 1;
}

.van-icon-share-o:before {
  color: #ffffff;
  opacity: 1;
}

.course-detail-title .van-nav-bar__content .van-nav-bar__right .van-icon {
  padding: 0.5rem;
  font-size: 3rem;
  border-radius: 5rem;
  background-color: rgba(0, 0, 0, 0.4);
  margin-right: 0.5rem;
}

.course-detail-title::after {
  border-width: 0 !important;
}

.icon-suo1 {
  font-size: 3.6rem;
  color: #969799;
}

.icon-bofang2 {
  font-size: 3.6rem;
  color: #656466;
}

.icon-bofang {
  font-size: 3.2rem;
  color: #1989fa;
}

.icon-zhengzaibofang {
  font-size: 3rem;
  color: #1989fa;
}

.van-goods-action {
  height: 6.5rem;
}

.shop_detail .courseList1 {
  margin: 0.5rem 1rem 1rem 1rem;
  border-radius: 1rem;
  background-color: #ffffff;
  padding-bottom: 1.5rem;
}

.shop_detail .courseList1 .van-cell {
  border-radius: 1rem !important;
  padding: 1rem 1.5rem;
}

.shop_detail .courseList1 .answering::after {
  border-width: 0 !important;
}

.shop_detail .courseList1 .answering .custom-title {
  font-size: 1.8rem;
  padding-right: 1rem;
}

.shop_detail .courseList1 .answering .van-icon {
  font-size: 3.6rem;
  padding-right: 1rem;
}

.shop_detail .courseList1 .answering .van-cell__label {
  color: #323233;
  font-size: 1.8rem;
}

/* 答疑提问 */
.answering-question {
  position: fixed;
  bottom: 0.5rem;
  width: 100%;
  text-align: center;
}

.answering-question .question-ask {
  height: 5rem !important;
  width: 90%;
}

.answering-question .edit {
  height: 5rem !important;
  width: 90%;
}


.shop_detail .courseList1 .note .custom-title {
  font-size: 1.8rem;
  padding-right: 1rem;
}

.shop_detail .courseList1 .note .van-cell__title .van-tag {
  font-size: 1.6rem !important;
}

.shop_detail .courseList1 .note .van-icon {
  font-size: 3.6rem;
  padding-right: 1rem;
}

.shop_detail .courseList1 .note .van-cell__label {
  color: #323233;
  font-size: 1.8rem;
}

.shop_detail .courseList1 .course-question-tag1 .van-tag {
  margin-right: 0.5rem;
  font-size: 1.6rem !important;
  padding: 0.2rem 0.8rem !important;
  height: 3rem;
}

.shop_detail .courseList1 .course-question-tag1 {
  margin: 0.1rem 2% 0.1rem 2%;
  padding: 0 !important;
  font-size: 1.8rem !important;
  width: 96%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: #323233 !important;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.shop_detail .courseList1 .course-question-tag-time1 {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.shop_detail .courseList1 .course-question-tag-time1 .van-tag {
  margin: 1rem 1rem 1.5rem 1rem;
  padding: 0.2rem 1rem;
}

.shop_detail .courseList1 .course-question-tag-time1 .van-icon {
  padding-right: 0.3rem;
}

.shop_detail .courseList1 .course-question-tag-time1 .reply {
  position: absolute;
  right: 0;
  color: #1989fa;
}

.shop_detail .courseList1 .reply_tag {
  background-color: #f0f0f0;
  border-radius: 3rem;
  padding-left: 0.3rem;
  height: 3.2rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 90%;
  margin: 0 auto;
}

.shop_detail .courseList1 .reply_tag .van-tag {
  height: 2.8rem !important;
  // width: 3rem !important;
  border-radius: 3rem;
  padding-left: 0.5rem;
  font-size: 1.8rem !important;
}

.shop_detail .courseList1 .reply_tag .reply_text {
  padding-left: 0.2rem;
  font-size: 1.8rem;
  color: #646566;
}

.practice {
  padding: 0 1rem;
}

.practice .van-cell__title {
  font-size: 1.8rem;
}

.vjs-custom-skin > .video-js .vjs-big-play-button {
  background-color: rgba(0, 0, 0, 0.45);
  font-size: 4.0rem;
  border-radius: 100%;
  width: 6rem !important;
  height: 6rem !important;
  line-height: 3rem !important;
  margin-top: -3rem !important;
  margin-left: -3rem !important;
  margin: 0 auto;
}

.vjs-custom-skin > .video-js .vjs-big-play-button .vjs-control-text {
  display: none;
}

.vjs-custom-skin > .video-js .vjs-big-play-button .vjs-icon-placeholder:before {
  position: relative;
  width: 5.6rem;
  height: 5.6rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.vjs-custom-skin > .video-js .vjs-error-display {
  display: none;
}

/*这里用了第三方vue-video-player插件，但这个插件有bug，设置globalSetting:{controls:true}隐藏进度条不生效，故可设置插件样式进行隐藏vjs-progress-contro*/
.vjs-progress-control {
  /*visibility:hidden;       // 隐藏进度条*/
}

.vjs-duration {
  height: 20rem;
}

.vjs-custom-skin > .video-js .vjs-control-bar {
  font-size: 2rem;
}

.vjs-custom-skin > .video-js .vjs-volume-panel {
  display: none;
}
</style>
