<template>
    <div class="course-detail">
        <van-nav-bar class="course-detail-title" fixed left-arrow @click-left="onBack" style="background: none;">
<!--            <template #right @click-right="userKefu">-->
<!--                <van-icon name="share-o" @click="userKefu"/>-->
<!--            </template>-->
        </van-nav-bar>

        <div class="banner-course-detail" ref="attrRef">
            <video
                    id="myVideo"
                    ref="myVideo"
                    class="video-js"
                    :src="videoUrl"
                    controls
                    preload="auto"
                    oncontextmenu="return false"
                    :poster="poster"
                    :videoData="studyTime"
                    :style="contentStyleObj"
                    @timeupdate="timeupdate"
            >
            </video>
        </div>
        <!-- <div class="course-title">
          <van-tag type="warning">直播+录播</van-tag>2022年中药学综合知识与技能（精品班）
          <div class="course-price">￥980.00</div>
        </div> -->
        <!-- <van-cell center title="2022年中药学综合知识与技能（精品班）" class="course-service" @click="serviceShow = true">
          <template #right-icon>
            <van-tag round type="warning"><van-icon name="share" size="2rem" />分享</van-tag>
          </template>
        </van-cell> -->
        <van-tabs v-model:active="active" color="#1989fa" swipeable animated class="shop_detail"
                  :style="contentStyleObj1">
            <van-tab title="目录">
                <div v-if="courseList.length > 0">
                    <div class="courseList" v-for="(item,index) in courseList" :key="index">
                        <van-collapse v-model="activeNames">
                            <van-collapse-item :name="index">
                                <template #title>
                                    <div class="courseListTitle">
                                        <van-tag round color="#f5f5f5" text-color="#323233" size="medium">
                                            {{item.cate_name}}
                                        </van-tag>
                                    </div>
                                </template>
                                <div v-for="(itemText,index2) in item.data" :key="index2">
                                    <!-- 解锁的视频 -->
                                    <van-cell center v-if="itemText.audition==2" :data-id="itemText.id" @click="getVideo(itemText.id)">
                                        <template #title>
                                            <span :style="{'color': (keyIndex==1 && itemText.id==courseWareId)?'#1989fa':'#323233'}">{{itemText.video_title}}</span>
                                        </template>
                                        <template #label>
                                            <span :style="{'color': (keyIndex==1 && itemText.id==courseWareId)?'#1989fa':'#969799'}">时长：{{itemText.video_duration}}丨{{itemText.teacher_id}}</span>
                                        </template>
                                        <template #right-icon>
                                            <span class="iconfont " v-if="(keyIndex==1 && itemText.id==courseWareId)" :class="[videoState==true?'icon-zhengzaibofang':'icon-bofang']"></span>
                                            <span class="iconfont icon-bofang2" v-else></span>
                                        </template>
                                    </van-cell>
                                    <!-- 带锁的视频 -->
                                    <van-cell center :title="itemText.video_title" v-if="itemText.audition==1" @click="Dialog">
                                        <template #label>
                                            <span>时长：{{itemText.video_duration}}丨{{itemText.teacher_id}}</span>
                                        </template>
                                        <template #right-icon>
                                            <span class="iconfont icon-suo1"></span>
                                        </template>
                                    </van-cell>
                                </div>
                            </van-collapse-item>
                        </van-collapse>
                    </div>
                </div>
                <div v-else>
                    <van-empty image-size="30rem" description="课程暂未更新" />
                </div>
            </van-tab>
            <van-tab title="讲义">

                <van-image
                        v-if="handout"
                        width="100%"
                        height="auto"
                        lazy-load
                        :src="handout"
                />
                 <van-empty v-else image-size="30rem" description="该课程暂无讲义" />
            </van-tab>
<!--            <van-tab title="讲义">-->
<!--                <van-empty image-size="30rem" description="课程暂未更新" />-->
<!--            </van-tab>-->
        </van-tabs>


        <van-action-sheet v-model:show="serviceShow" title="课程配套服务" class="service-popup">
            <div class="content2" height="40%">
                <van-cell center title="七天退款" label="描述内容在这里">
                    <template #icon>
                        <van-icon name="fire-o"/>
                    </template>
                </van-cell>
                <van-cell center title="七天退款" label="描述内容在这里">
                    <template #icon>
                        <van-icon name="fire-o"/>
                    </template>
                </van-cell>
                <van-cell center title="七天退款" label="描述内容在这里">
                    <template #icon>
                        <van-icon name="fire-o"/>
                    </template>
                </van-cell>
                <van-cell center title="七天退款" label="描述内容在这里">
                    <template #icon>
                        <van-icon name="fire-o"/>
                    </template>
                </van-cell>
                <van-cell center title="七天退款" label="描述内容在这里">
                    <template #icon>
                        <van-icon name="fire-o"/>
                    </template>
                </van-cell>
                <van-cell center title="七天退款" label="描述内容在这里">
                    <template #icon>
                        <van-icon name="fire-o"/>
                    </template>
                </van-cell>
            </div>
        </van-action-sheet>
        <van-action-sheet v-model:show="teacherShow" title="我的服务老师" class="teacher-popup">
            <div class="content2" height="40%">
                <van-cell center title="王老师电话">
                    <template #icon>
                        <van-icon name="phone-o"/>
                    </template>
                    <template #label>
                        <span>18810834233<van-tag style="margin-left:0.6rem">复制</van-tag></span>
                    </template>
                </van-cell>
                <van-cell center title="王老师微信">
                    <template #icon>
                        <van-icon name="chat-o"/>
                    </template>
                    <template #label>
                        <span>18810834233<van-tag style="margin-left:0.6rem">复制</van-tag></span>
                    </template>
                </van-cell>
                <van-image class="pho-wx" width="20rem" height="20rem" fit="contain"
                           src="https://cdnoss.ksyt.com.cn/wxImages/icon-touxiang.png"/>
            </div>
        </van-action-sheet>

<!--        <van-goods-action>-->
<!--            <van-goods-action-button type="warning" text="返回上一页" @click="handelFreeAudition"/>-->
<!--            <van-goods-action-button type="danger" text="推荐给朋友" @click="onShare"/>-->
<!--        </van-goods-action>-->

    </div>
</template>

<script>
    var pageUrl = '';
    // @ is an alias to /src
    // import HelloWorld from '@/components/HelloWorld.vue'
    // import axios from "axios"


    import VideoPlayer from '@/components/VideoPlayer'
    import {getCoursewareInfo, GetHomeList, onCourseDetail, onShareUrl,getCourseWareVideoUrl } from '@/request/api'
    import Qs from 'qs'
    import wx from 'weixin-js-sdk';
    export default {
        beforeRouteEnter(to, from, next) {
            let that = this;
            console.log(to);
            console.log(from);
            pageUrl = to.name;
            // console.log(next);
            // if (!to.params || !to.params.id) {
            //     next({
            //         path: from.name,
            //         query: {} // 将跳转的路由path作为参数，登录成功后跳转到该路由
            //     })
            // }
            next();
        },
        // metaInfo: {
            // title: '当前班次名称在这里', //设置页面title
            // meta: [{                 //设置页面meta
            //     name: 'keyWords',
            //     content: '关键词'
            // },
            //     {
            //         name: "description",
            //         content: "描述",
            //     },
            // ]
        // },
        metaInfo () {
            return {
                title: this.title, //设置页面title
                meta: [{                 //设置页面meta
                    name: 'keyWords',
                    content: this.website.seo_keywords
                },
                    {
                        name: "description",
                        content: this.website.seo_description,
                    },
                ]
            }
        },
        data() {
            return {
                activeNames: [0],
                isShowBo: true,
                banner: [],
                current: 0,
                active: '',
                serviceShow: false,
                teacherShow: false,
                status: '',
                contentStyleObj: {
                    height: '',
                    width: ''
                },
                contentStyleObj1: {
                    top: ''
                },
                msg: '',
                poster: 'https://cdnoss.ksyt.com.cn/school/20220909/videoImg.png',
                videoUrl: '',
                videoState: false, // 视频播放状态
                // 学时
                studyTime: {
                    currentTime: 0, // 当前已学时长
                    duration: 0 // 总时长
                },
                timer: {}, // 定时器
                pauseTimer: {}, // 暂停定时器
                courseList2: [],
                courseList: [],
                courseWareId: '',
                keyIndex: '',
                jssdk: [],
                title: '精品课程免费试听',
                photopath: 'https://cdnoss.ksyt.com.cn/school/51e7b00a0c42d29e1c7ce182ca20634.jpg',
                website: [],
                handout: '',
            };
        },
        mounted() {
            this.contentStyleObj.height = this.$refs.attrRef.offsetWidth * 9 / 16 + 'px ' + '!important';
            this.contentStyleObj.width = window.getComputedStyle(this.$refs.attrRef).width + ' ' + '!important';
            // top = this.contentStyleObj.height;
            this.contentStyleObj1.top = this.contentStyleObj.height;


            this.src = "https://video.ksyt.com.cn/e54c29ac9e974a95a195072432aaefd3/f90c485daf5d4dd6a9cac448d785a01f-1347ea9bd7105c6d4ed30c8645e1df12-ld.mp4";

            // 监听视频播放
            this.$refs.myVideo.addEventListener("play", () => {
                console.log("video is playing");
                console.log(this.$refs.myVideo.src);
                this.openTimer();
                this.videoState = true;
                this.isShowBo = false;

            });
            // 监听视频暂停
            this.$refs.myVideo.addEventListener("pause", () => {
                console.log("video is stop");
                this.closeTimer();
                this.videoState = false;
                this.isShowBo = false;

            });
        },
        methods: {

            // 开启定时器
            openTimer() {
                this.timer = setInterval(() => {
                    this.$emit("videoStudyTime", this.studyTime);
                    // console.log(this.studyTime)
                }, 5000);
            },
            // 关闭定时器
            closeTimer() {
                clearInterval(this.timer);
                this.$emit("videoStudyTime", this.studyTime);
            },
            // 开启暂停定时器
            openPauseTimer() {
                this.pauseTimer = setInterval(() => {
                    this.hintOperate();
                }, 6000);
            },
            // 关闭暂停定时器
            closePauseTimer() {
                clearInterval(this.pauseTimer);
            },
            // 提示操作
            hintOperate() {

                this.operateVideo();
                this.$dialog.confirm({
                    title: '课程提示',
                    message: '请点击确认继续学习',
                    confirmButtonColor: '#1989fa'
                }).then(() => {
                    this.$refs.myVideo.currentTime = this.videoData.currentTime;
                    this.operateVideo();
                    this.openPauseTimer();
                }).catch(() => {
                    console.log('点击了取消')
                })
            },
            // 获取当前播放位置
            timeupdate(e) {
                // console.log(e);
                this.studyTime.currentTime = e.target.currentTime;
                this.studyTime.duration = e.target.duration ? e.target.duration : 0;
                // console.log(this.studyTime.duration);
            },

            // 操作视频播放、暂停
            operateVideo() {
                this.$dialog.alert({
                    // title:'付费提示',
                    message: '暂无视频资源\n请查看其他视频',
                    confirmButtonColor: '#1989fa'
                }).then(() => {
                    console.log('点击了确认')
                }).catch(() => {
                    console.log('点击了取消')
                })
                return false;
            },


            //（确认、取消）的弹出框
            Dialog() {
                this.$dialog.confirm({
                    title: '付费提示',
                    message: '当前为免费试听模式，\n付费后即可解锁听课权限',
                    confirmButtonColor: '#1989fa'
                }).then(() => {
                    console.log('点击了确认')
                }).catch(() => {
                    console.log('点击了取消')
                })
            },


            handleClickSearch() {
                this.$router.push('/course/searchPopup')
                // 修改vuex中的 isShowPopupShow 值为true
                // this.$store.commit("changeIsShowPopupShow", true)
            },
            onChange(index) {
                this.current = index;
            },
            handelService() {
                this.$router.push('/course/searchPopup')
                // 修改vuex中的 isShowPopupShow 值为true
                // this.$store.commit("changeIsShowPopupShow", true)
            },
            handelFreeAudition() {
                if(window.android != undefined && this.isWeiXin() === false) {
                    this.$router.push(
                        {
                            name:'FreeAudition', //Audition
                            query:{
                                groupId:this.$route.query.groupId2,
                                subjectId:this.$route.query.subjectId2
                            }
                        }
                    );
                } else {
                    this.$router.push(
                        {
                            name:pageUrl?pageUrl:'Audition',
                            query:{
                                groupId:this.$route.query.groupId2,
                                subjectId:this.$route.query.subjectId2
                            }
                        }
                    );
                }

                // 修改vuex中的 isShowPopupShow 值为true
                // this.$store.commit("changeIsShowPopupShow", true)
            },


            onBack() {
                // history.back();
                if(window.android != undefined && this.isWeiXin() === false) {
                    this.$router.push(
                        {
                            name:'FreeAudition', //Audition
                            query:{
                                groupId:this.$route.query.groupId2,
                                subjectId:this.$route.query.subjectId2
                            }
                        }
                    );
                } else {
                    this.$router.back();
                    this.$router.push(
                        {
                            name:'Audition', //
                            query:{
                                groupId:this.$route.query.groupId2,
                                subjectId:this.$route.query.subjectId2
                            }
                        }
                    );
                }
            },
            onShare() {
                history.back();
            },
            userKefu() {

            },
            //播放url
            getVideo(id) {
                let that = this;
                console.log(id,that.courseWareId);
                if(id == that.courseWareId) {
                    return false;
                }
                getCourseWareVideoUrl({id})
                    .then(res => {
                        if (res.data.code == 1) {
                            if(id == that.courseWareId) {
                                return false;
                            }
                            that.$refs.myVideo.src = res.data.data.url;
                            that.courseWareId = id;
                            that.videoState = true;
                            that.keyIndex = 1;
                            that.title = res.data.data.video_title;
                            that.handout = res.data.data.videoHandout;
                            if (that.$refs.myVideo.paused) {
                                that.$refs.myVideo.play();
                            } else {
                                that.$refs.myVideo.pause();
                            }
                        } else {
                            that.$dialog.alert({
                                // title:'付费提示',
                                message: '暂无视频资源\n请查看其他视频',
                                confirmButtonColor: '#1989fa'
                            }).then(() => {
                                console.log('点击了确认')
                            }).catch(() => {
                                console.log('点击了取消')
                            })
                            return false;
                        }
                    })
                    .catch(err => {
                        console.log("error");
                        console.log(err);
                    })
            }
        },
        created() {
            // request.get("/index/index")
            // GetHomeList()
            //     .then(res => {
            //         // console.log("success!!!!!111");
            //         // console.log(res);
            //         this.banner = res.data.data.banner
            //         // console.log(this.banner);
            //     })
            //     .catch(err => {
            //         console.log("error");
            //         console.log(err);
            //     });
            onCourseDetail({subject_id: this.$route.query.subjectId, stageid: this.$route.query.stageId,courseId:this.$route.query.courseId})
                .then(res => {
                    if (res.data.code == 1) {
                        this.courseList = res.data.data.data;
                        this.website = res.data.data.website;
                        let courseList = res.data.data.data
                        for (let item in this.courseList) {
                            for (let item2 in courseList[item]['data']) {
                                if (courseList[item]['data'][item2].audition == 2) {
                                    console.log(courseList[item]['data'][item2].id);
                                    this.getVideo(courseList[item]['data'][item2].id);
                                    throw Error();
                                }
                            }
                        }
                    }
                    // this.$store.state.cart.forEach(item => {
                    //     if (item.flag) {
                    //         this.$store.state.cart.splice(item, 1)
                    //         this.result.splice(item.id, 1)
                    //     }
                    // })
                    // console.log("success!!!!!111");
                    // console.log(res);
                    // this.courseList = res.data.data.banner
                    // console.log(this.banner);
                })
                .catch(err => {
                    console.log("error");
                    console.log(err);
                })
            onShareUrl({url: location.href.split('#')[0]})
                .then(res => {
                    if (res.data.code == 1) {
                        wx.config({
                            debug: false,// 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
                            appId: res.data.data.sdk.appId,         // 必填，公众号的唯一标识，填自己的！
                            timestamp: res.data.data.sdk.timestamp, // 必填，生成签名的时间戳，刚才接口拿到的数据
                            nonceStr: res.data.data.sdk.nonceStr,   // 必填，生成签名的随机串
                            signature: res.data.data.sdk.signature, // 必填，签名，见附录1
                            jsApiList: [
                                'onMenuShareTimeline',
                                'onMenuShareAppMessage'
                            ]
                        })
                        let that = this;
                        console.log(this.title);
                        wx.ready(function () {
                            //分享到朋友圈
                            wx.onMenuShareTimeline({
                                title:  that.title,   // 分享时的标题
                                link: location.href,     // 分享时的链接
                                desc: '高清视频，同步字幕，名师精讲，像看电影一样的学习体验，助你轻松备考！',
                                imgUrl: that.photopath,    // 分享时的图标
                                success: function () {
                                    console.log("分享成功");
                                },
                                cancel: function () {
                                    console.log("取消分享");
                                }
                            });
                            //分享给朋友
                            wx.onMenuShareAppMessage({
                                title:  that.title,
                                // desc: '这件商品终于优惠了，每件只需'+pri_fx+'元',
                                desc: '高清视频，同步字幕，名师精讲，像看电影一样的学习体验，助你轻松备考！',
                                link: location.href,
                                imgUrl: that.photopath,
                                type: '',
                                dataUrl: '',
                                success: function () {
                                    console.log("分享成功");
                                },
                                cancel: function () {
                                    console.log("取消分享");
                                }
                            });
                        });
                        wx.error(function(res){
                            console.log(res);
                            // config信息验证失败会执行 error 函数，如签名过期导致验证失败，具体错误信息可以打开 config 的debug模式查看，也可以在返回的 res 参数中查看，对于 SPA 可以在这里更新签名。
                        })
                    }
                })
                .catch(err => {
                    console.log("error");
                    console.log(err);
                })
        },
        components: {},
        watch: {
            // 监听操作
            videoData(val, oldVal) {
                const {currentTime, duration} = val;
                if (currentTime && duration && currentTime < duration) {
                    this.hintOperate();
                }
            }
        }

    }
</script>
<style lang="less">
    @import '~@/assets/styles/common.less';
    @import '~@/assets/iconfont/iconfont.css';


    // body {
    //   overflow: hidden;
    // }

    .course-detail {
        background-color: #ffffff;
    }

    .banner-course-detail {
        position: fixed;
        width: 100%;
        z-index: 99;
    }

    .popup-shadow {
        width: 100%;
        height: 100%;
        position: absolute;
        // z-index: 99999;
        left: 0;
        top: 0;
        background-color: rgba(0, 0, 0, .5);
        z-index: 20;
    }

    // .slide-enter, .slide-leave-to {
    //   // 过渡之前的样式写在这里
    //    right: -100%;
    // }
    // .slide-enter-active,.slide-leave-active  {
    //   // 过渡属性写在这里
    //   transition: all .3s;
    // }
    // .slide-enter-to, .slide-leave {
    //   // 过渡之后的样式写在这里
    //    right: 0;
    // }
    .shoplist1 {
        padding: 0.8rem;
        background-color: #ffffff;
    }

    // .course-title {
    //   padding: 2.4rem 1.5rem 1.5rem 1.5rem;
    //   font-size: 2.4rem;
    //   line-height: 3.6rem;
    //   font-weight: 600;
    //   color: #333;
    // }

    // .course-title .van-tag {
    //   padding: 0.3rem 0.6rem;
    //   position: relative;
    //   bottom: 0.2rem;
    //   margin-right: 0.6rem;
    // }

    // .course-title .course-price {
    //   padding-top: 0.5rem;
    //   color: #ee0a24;
    //   font-weight: 600;
    //   font-size: 2.4rem;
    // }

    .course-service {
        padding: 0 1.5rem;
    }

    .course-service .van-tag {
        margin-right: 0.4rem;
    }

    .course-service .weapp-nav-icon {
        font-size: 3rem;
    }

    .service-popup {
        height: 50% !important;;
    }

    .service-popup .content2 {
        padding: 1.2rem 0;
    }

    .service-popup .content2 .van-cell {
        padding: 0 1.5rem;
        margin: 2rem 0;
    }

    .service-popup .content2 .van-cell::after {
        border-width: 0;
    }

    .service-popup .content2 .van-cell .van-icon {
        font-size: 4rem;
        margin-right: 0.6rem;
    }

    .service-popup .content2 .van-cell .van-cell__title {
        font-size: 2rem;
    }

    .service-popup .content2 .van-cell .van-cell__label {
        font-size: 1.6rem;
    }


    .teacher-popup {
        height: 50% !important;;
    }

    .teacher-popup .content2 {
        padding: 1.2rem 0;
    }

    .teacher-popup .content2 .van-cell {
        padding: 0 1.5rem;
        margin: 2rem 0;
    }

    .teacher-popup .content2 .van-cell::after {
        border-width: 0;
    }

    .teacher-popup .content2 .van-cell .van-icon {
        font-size: 4rem;
        margin-right: 0.6rem;
        color: #666666;
    }

    .teacher-popup .content2 .van-cell .van-cell__title {
        font-size: 2rem;
    }

    .teacher-popup .content2 .van-cell .van-cell__label {
        font-size: 2rem;
        line-height: 4rem;
    }

    .teacher-popup .content2 .pho-wx {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
    }

    .shop_detail {
        background-color: #e7e9ed;
        position: fixed;
        width: 100%;
        bottom: 0;
        overflow-y: scroll;
        overflow-x: hidden;
    }

    .shop_detail .van-tabs__wrap {
        position: fixed;
        z-index: 100;
        width: 100%;
    }

    .shop_detail .van-tabs__content {
        padding-bottom: 6rem;
        padding-top: 6.5rem;
    }

    .shop_detail .courseList {
        margin: 0.5rem 1rem 1rem 1rem;
        border-radius: 2rem;
        background-color: #ffffff;
    }

    .shop_detail .courseList .courseListTitle {
        background-color: #ffffff;
        font-size: 2rem;
        line-height: 3rem;
        border-top-left-radius: 1rem;
        border-top-right-radius: 1rem;
    }

    .shop_detail .courseList .courseListTitle .van-tag {
        font-size: 2rem !important;
        line-height: 3rem !important;
        padding: 1rem 1.5rem !important;
        font-weight: 600;
        text-overflow: clip;
        overflow: hidden;
        white-space: nowrap;
    }

    .shop_detail .courseList .van-cell {
        border-radius: 1rem !important;
        padding: 1rem 1.5rem;
    }

    .van-collapse .van-collapse-item .van-collapse-item__wrapper {
        border-radius: 1rem;
    }

    .van-icon-like:before {
        color: #ee0a24 !important;
    }

    // .shop_detail .evaluation {
    //   margin: 1rem;
    //   border-radius: 2rem;
    //   background-color: #ffffff;
    // }

    // .shop_detail .evaluation .van-cell {
    //   border-radius: 1rem;
    //   padding: 1.5rem 1rem;
    // }

    // .shop_detail .evaluation .van-cell .van-icon {
    //   float: left;
    //   top: 0;
    //   margin: 0.3rem 0.6rem;
    // }

    // .shop_detail .evaluation .van-cell .van-icon .van-icon__image {
    //   border-radius: 100%;
    // }

    // .shop_detail .evaluation .van-cell .van-cell__title {
    //   color: #333;
    //   font-size: 1.8rem;
    // }

    // .shop_detail .evaluation .van-cell .evaluationContent {
    //   color: #333;
    //   font-size: 1.8rem;
    //   line-height: 3.2rem;
    // }

    // .shop_detail .evaluation .title-1 {
    //   height: 4rem !important;
    // }

    // .shop_detail .evaluation .title-1 .van-cell__title {
    //   color: #333;
    //   font-size: 2.2rem;
    //   font-weight: 600;
    //   padding: 0 0.5rem;
    // }

    .shop_detail .title-2 {
        height: 4rem !important;
    }

    .shop_detail .title-2 .van-cell__title {
        color: #333;
        font-size: 2.2rem;
        font-weight: 600;
        padding: 0 1rem !important;
    }

    .course-detail-title {
        position: fixed;
        z-index: 100;
        height: 5rem !important;
    }

    .course-detail-title .van-nav-bar__content .van-icon {
        color: #ffffff;
    }

    .course-detail-title .van-nav-bar__content .van-nav-bar__left .van-icon {
        font-size: 3rem;
        padding: 0.5rem;
        border-radius: 5rem;
        background-color: rgba(0, 0, 0, 0.4);
        margin-left: 0.5rem;
    }

    .course-detail .van-icon-arrow-left:before {
        color: #ffffff;
        opacity: 1;
    }

    .van-icon-share-o:before {
        color: #ffffff;
        opacity: 1;
    }

    .course-detail-title .van-nav-bar__content .van-nav-bar__right .van-icon {
        padding: 0.5rem;
        font-size: 3rem;
        border-radius: 5rem;
        background-color: rgba(0, 0, 0, 0.4);
        margin-right: 0.5rem;
    }

    .course-detail-title::after {
        border-bottom-width: 0 !important;
    }

    .icon-suo1 {
        font-size: 3.6rem;
        color: #969799;
    }

    .icon-bofang2 {
        font-size: 3.6rem;
        color: #656466;
    }

    .icon-bofang {
        font-size: 3.2rem;
        color: #1989fa;
    }

    .icon-zhengzaibofang {
        font-size: 3rem;
        color: #1989fa;
    }

    .van-goods-action {
        height: 6.5rem;
    }
</style>
