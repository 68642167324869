<template>
    <div>
        <div class="examClass">
            <van-nav-bar class="cart-title" fixed @click-left="onBack" @click-right="isDelete = !isDelete">
                <template #left>
                    <van-icon name="arrow-left" color="#646566" size="3rem" style="padding: 0 1rem;" />
                </template>
                <template #title>{{questionCateTitle}}</template>
                <!-- <template #right >
                    <van-dropdown-menu class="rightDropdown"  active-color="#1989fa">
                    <van-dropdown-item v-model="value1" :options="option1" />
                    </van-dropdown-menu>
                </template> -->
            </van-nav-bar>

            <van-image
                    width="100%"
                    height="10rem"
                    fit="fill"
                    :src="info.photopath2"
                    class="imgBox"
            />

            <div class="question-subject-tab011" v-if="info">
                <van-tabs class="subject-tab" v-model:active="active" color="#1989fa" animated swipeable @click="clickTab">
                    <van-tab title="练习" name="0" v-if="data.length > 0">
                        <div v-for="(item2,index2) in data" :key="index2" >
                            <van-cell v-if="item2.exam_type == 1" class="question-exam011" center @click="item2.answer?onTestPaperAnswer(item2.answer,item2.exam_type):onTestPaper(item2.id,item2.type,item2.exam_type)">
                                <template #title>
                                    <van-tag type="warning" class="examTypeBtn"> 做一次 </van-tag> 
                                    <span>{{item2.title}}</span>
                                </template>
                                <template #icon>
                                    <van-icon class="left-icon01" name="https://cdnoss.ksyt.com.cn/weixin/icon_practice030.png" />
                                </template>
                                <template #right-icon>
                                    <div class="yichuTag">
                                        <van-tag v-if="item2.answer" color="#646566" round plain size="medium" @click="onTestPaperAnswer(item2.answer,item2.exam_type)">查看解析</van-tag>
                                        <van-tag v-else type="primary" round size="medium" @click="onTestPaper(item2.id,item2.type,item2.exam_type)">进入做题</van-tag>
                                    </div>
                                </template>
                                <template #label>
                                    <span>共{{item2.question_num}}</span>题， <span>{{ item2.count }}人做过</span>
                                </template>
                            </van-cell>
                            <van-cell v-else class="question-exam011" center @click="item2.answer?onTestPaperAnswer(item2.answer,item2.exam_type):onTestPaper(item2.id,item2.type,item2.exam_type)">
                                <template #title>
                                    <!-- <van-tag type="danger" class="examTypeBtn" v-if="item2.exam_type == 1"> 做一次 </van-tag>  -->
                                    <span>{{item2.title}}</span>
                                </template>
                                <template #icon>
                                    <van-icon class="left-icon01" name="https://cdnoss.ksyt.com.cn/weixin/icon_practice030.png" />
                                </template>
                                <template #right-icon>
                                    <!-- <div v-if="item2.exam_type == 1" class="yichuTag">
                                        <van-tag v-if="item2.answer" color="#646566" round plain size="medium" @click="onTestPaperAnswer(item2.answer,item2.exam_type)">查看解析</van-tag>
                                        <van-tag v-else type="primary" round size="medium" @click="onTestPaper(item2.id,item2.type,item2.exam_type)">进入做题</van-tag>
                                    </div> -->
                                    <div  class="yichuTag">
                                        <van-tag v-if="item2.answer" color="#646566" round plain size="medium" @click="onTestPaperAnswer(item2.answer,item2.exam_type)">查看解析</van-tag>
                                        <van-tag type="primary" round size="medium" @click="onTestPaper(item2.id,item2.type,item2.exam_type)" style="margin-top: 1rem;">重新做题</van-tag>
                                    </div>
                                </template>
                                <template #label>
                                    <span>共{{item2.question_num}}</span>题， <span>{{ item2.count }}人做过</span>
                                </template>
                            </van-cell>
                        </div>
                    </van-tab>
                    <van-tab title="考试" name="1" v-if="data2.length > 0">
                        <div v-for="(item2,index2) in data2" :key="index2">
                            <van-cell class="question-exam011" center @click="item2.answer?onTestPaperAnswer(item2.answer,item2.exam_type):onTestPaper(item2.id,item2.type,item2.exam_type)">
                                <template #title>
                                    <van-tag type="warning" class="examTypeBtn" v-if="item2.exam_type == 1"> 做一次 </van-tag> 
                                    <span>{{item2.title}}</span>
                                </template>
                                <template #icon>
                                    <van-icon class="left-icon01" name="https://cdnoss.ksyt.com.cn/weixin/icon_exam030.png"/>
                                </template>
                                <template #right-icon>
                                    <div v-if="item2.exam_type == 1" class="yichuTag">
                                        <van-tag v-if="item2.answer" color="#646566" round plain size="medium" @click="onTestPaperAnswer(item2.answer,item2.exam_type)">查看解析</van-tag>
                                        <van-tag v-else type="primary" round size="medium" @click="onTestPaper(item2.id,item2.type,item2.exam_type)">进入做题</van-tag>
                                    </div>
                                    <div v-else class="yichuTag">
                                        <van-tag v-if="item2.answer" color="#646566" round plain size="medium" @click="onTestPaperAnswer(item2.answer,item2.exam_type)">查看解析</van-tag>
                                        <van-tag type="primary" round size="medium" @click="onTestPaper(item2.id,item2.type,item2.exam_type)" style="margin-top: 1rem;">重新做题</van-tag>
                                    </div>
                                </template>
                                <template #label>
                                    <span>共{{item2.question_num}}</span>题， <span>{{ item2.count }}人做过</span>
                                </template>
                            </van-cell>
                        </div>
                    </van-tab>
                </van-tabs>
            </div>
            <div v-else>
                <van-empty
                        description="暂无试卷"
                        image="https://cdnoss.ksyt.com.cn/wxImages/no-course.png"
                        image-size="24rem">
                    <van-button round color="#1989fa" size="normal" class="bottom-button" icon="plus" @click="mySchool">联系客服</van-button>
                </van-empty>
            </div>
            <!-- 弹出框（试卷状态） -->
            <van-popup v-model="showKS" class="question-content1" v-if="currentInfo.state == '0'">
                <div class="question-content-popup">
                    <van-icon name="warning-o" color="#ee0a24"/>
                    <div>该试卷还在整理中，暂未上架，敬请期待哦~</div>
                </div>
            </van-popup>
            <!-- 弹出层（考试信息） -->
            <van-popup v-model="showKS" :style="{ height: '70%',width:'95%' }" v-if="currentInfo.state == '1'">
                <examKsxx :currentInfo="currentInfo" :type="2" @closeFun="closeFun"></examKsxx>
            </van-popup>
            <van-popup class="resultPop" v-model="showLogin" position="right" :style="{ height: '100%', width: '100%' }">
                <zxksKsdtqk :testPaperAnswerId="testPaperAnswerId" url="QuestionRecord" :examType="exam_type" source="3" @againQuestion="againQuestion" ></zxksKsdtqk>
            </van-popup>
        </div>

        <transition name="van-fade">
            <div class="login-modal" v-show="isShowModal">
                <div class="close-modal" @click="isShowModal=!isShowModal"></div>
                <van-form @submit="onSubmit" class="login-form" v-show="isShowLogin">
                    <div class="loginTitle">账号密码登录</div>
                    <van-field
                            v-model="username"
                            name="usernameLogin"
                            label="用户名"
                            placeholder="用户名"
                            :rules="[{ required: true,}]"
                    />
                    <van-field
                            v-model="password"
                            type="password"
                            name="passwordLogin"
                            label="密码"
                            placeholder="密码"
                            :rules="[{ required: true,}]"
                    />
                    <van-field name="agreement">
                        <template #input>
                            <van-radio-group v-model="checked" @change="changeFn">
                                <van-radio name="1" shape="square"  @click="clickFn">
                                    <div class="title">我已阅读并同意：</div>
                                    <span class="tit" @click="servicesBtn">《网校服务条款》</span>
                                    <span class="tit" @click="agreementBtn">《用户隐私协议》</span>
                                </van-radio>
                            </van-radio-group>
                        </template>
                    </van-field>
                    <div style="margin: 2rem;">
                        <van-button round block type="info" size="large" native-type="submit">登录</van-button>
                    </div>
                </van-form>
            </div>
        </transition>
    </div>

</template>

<script>
    var url = '';
    // 考试信息的弹框
    import examKsxx from '@/modules/exam_ksxx'
    import {Toast} from "vant";
    import {GoLogin, testPaperDiscountList, testPaperDiscountList2} from "@/request/api";
    // 考试结束答题情况弹框
    import zxksKsdtqk from '@/modules/zxks_ksdtqk'

    export default {
        beforeRouteEnter(to, from, next) {
            console.log(to,from);
            url = to.path
            console.log(url);
            next();
        },
        name: 'questionTrainingCamp',
        components: {examKsxx,zxksKsdtqk},
        data() {
            return {
                value1: 0,
                option1: [
                    { text: '中药学专业知识一', value: 0 },
                    { text: '中药学专业知识二', value: 1 },
                    { text: '中药学综合知识与技能', value: 2 },
                    { text: '药事管理与法规', value: 3 },
                ],
                activeNames: ['1'],
                active: '',
                item: '',
                selectChapter: true,
                selectPaper: false,
                icon_exam: "https://cdnoss.ksyt.com.cn/wxImages/assets/images/icon_exam.png",
                icon_plus: "https://cdnoss.ksyt.com.cn/wxImages/assets/images/icon_plus.png",
                icon_minus: "https://cdnoss.ksyt.com.cn/wxImages/assets/images/icon_minus.png",
                icon_choice: "https://cdnoss.ksyt.com.cn/wxImages/assets/images/icon_choice.png",
                show: false,
                showKS: false,
                msg: '',
                // 考试数据
                examObj: {},
                // 传给子组件的考当前试信息
                currentInfo: {},

                question_type_id: '',
                questionCateTitle: '试卷列表',
                subject_id: '',
                subjectList: [],
                testPaperList: [],
                appOrH5:false,
                exam_id: '',
                swiperList:[],
                info:[],
                data:[],
                data2:[],
                showLogin: false,
                testPaperAnswerId:'',
                examUrl:url,
                exam_type: '',
                isShowModal:false,
                isShowLogin:'true',
                isShowReg: false,
                nickname: "用户登录",
                username: '',
                usernameRegister: '',
                password: '',
                passwordRegister: '',
                checkRadioFlag: false,
                checked: '1'
            }
        },
        metaInfo() {
            return {
                title: this.questionCateTitle,
            }
        },
        created() {
            this.exam_id = this.$route.params.examId;
            // 获取考试列表
            this.getTestPaper();
        },

        methods: {
            changeFn(){
                this.checkRadioFlag = true;
            },
            clickFn(){
                if(!this.checkRadioFlag){
                    this.checked = '';
                }
                this.checkRadioFlag = false;
            },
            onSubmit(values) {
                let that = this;
                // console.log('submit', values);
                let branch = that.info.branch;
                let username = values["usernameLogin"];
                let pwd = values["passwordLogin"];
                let agreement = values["agreement"];
                if(agreement == '') {
                    Toast.fail('请阅读并同意服务条款和协议');
                }
                GoLogin({username, pwd, appid: branch}).then(res => {
                    if (res.data.status == 1) {
                        // 登录成功了
                        // 1.提示框提示登录成功
                        Toast.success('登录成功');
                        // 2.把token保存到本地存储
                        localStorage.setItem("token", res.data.data.jwt);
                        localStorage.setItem("userInfo", JSON.stringify(res.data.data.userInfo));
                        localStorage.setItem("branchId", res.data.data.userInfo.branchId);
                        localStorage.setItem("adminId", res.data.data.userInfo.adminId);
                        localStorage.setItem("groupId", res.data.data.userInfo.groupId);
                        localStorage.setItem("subjectId", res.data.data.userInfo.subjectId);
                        localStorage.setItem("title", res.data.data.userInfo.title);
                        // that.loginTime();
                        that.nickname = res.data.data.userInfo.username;
                        Toast.success('登录成功！');
                        that.isShowModal = false;
                        that.getTestPaper();
                        that.metaInfo

                    } else {
                        Toast.fail(res.data.msg);
                    }
                })
            },
            mySchool(){
                this.$router.push('/MySchool')
            },
            // 点击考试信息的遮罩层
            closeFun() {
                this.showKS = false
            },

            onQuestionChapter() {
                if (this.selectChapter == true) {
                    this.selectChapter = false
                } else if (this.selectChapter == false) {
                    this.selectChapter = true
                }
            },
            //试卷详情
            ksxxFun(val) {
                this.showKS = true
                this.currentInfo = val
            },

            toPaper() {
                // this.$router.push('/PaperDetail')
                this.$router.push('/Zxks')
            },

            onBack() {
                // history.back();
                // this.$router.push('/questionH5')
                let url = 'Question';
                if(window.android != undefined && this.isWeiXin() === false) {
                    if(window.android.isapp() == true) {
                        url = 'QuestionH5';
                    }
                }
                this.$router.push({
                    name:url,
                    params:{

                    },
                    query:{

                    }
                });
            },
            getTestPaper() {
                let that = this;
                const{exam_id} = that;
                let token = this.getToken();
                if(token) {
                    testPaperDiscountList2({exam_id})
                        .then(res => {
                            if (res.data.code == 1) {
                                that.swiperList = res.data.data.bannerData
                                that.info = res.data.data.info
                                that.data = res.data.data.data
                                that.data2 = res.data.data.data2
                                that.questionCateTitle = res.data.data.info.cate_name
                                if(res.data.data.data.length <=0) {
                                    that.active = 1;
                                }
                            }
                        })
                        .catch(err => {
                            console.log("error");
                            console.log(err);
                        })
                } else {
                    testPaperDiscountList({exam_id})
                        .then(res => {
                            if (res.data.code == 1) {
                                console.log(res.data.data.data);
                                that.swiperList = res.data.data.bannerData
                                that.info = res.data.data.info
                                that.data = res.data.data.data
                                that.data2 = res.data.data.data2
                                that.questionCateTitle = res.data.data.info.cate_name
                            }
                        })
                        .catch(err => {
                            console.log("error");
                            console.log(err);
                        })
                }

            },
            onTestPaper(id,type,exam_type) {
                let token = this.getToken()
                if(!token) {
                    console.log('没登录');
                    this.isShowModal = true;
                    return false;
                }
                // 考试模式
                if (type == 1) {
                    this.$router.push({
                        name: 'zxksDT',
                        query: {id, type: 2,exam_type}
                    })
                } else {
                    this.$router.push({
                        name: 'zxksDTLX',
                        query: {id, type: 1,exam_type}
                    })
                }
            },
            onTestPaperAnswer(id,type) {
                let token = this.getToken()
                if(!token) {
                    console.log('没登录');
                    this.isShowModal = true;
                    return false;
                }
                console.log(id,type,this.examUrl);
                this.testPaperAnswerId = id;
                this.exam_type = type;
                this.showLogin = true;
            },
            clickTab(name, title) {
                console.log(name,title);
                this.active = name;
            },
            //试卷详情
            questionDetail(id) {
                let that = this;
                testPaperDetail({test_paper_id: id})
                    .then(res => {
                        if (res.data.code == 1) {
                            that.showKS = true
                            that.currentInfo = res.data.data.data
                        }
                    })
                    .catch(err => {
                        console.log("error");
                        console.log(err);
                    })
            },
            //未购买 提示
            orderMsg(id) {
                Toast({
                    message: '题库未购买',
                    icon: 'lock',
                    position: 'top',
                    duration: 1000,
                });
            },
            againQuestion() {
                console.log(123123);
                this.showLogin = false;
            },
            servicesBtn(){
                let branch = this.getBranch();
                this.$router.push('/websiteInfo?branch='+branch+'&type=1')
                // this.$router.push('/freeAudition11')
                console.log("免费试听按钮被点击");
            },
            agreementBtn(){
                let branch = this.getBranch();
                this.$router.push('/websiteInfo?branch='+branch+'&type=2')
                // this.$router.push('/freeAudition11')
                console.log("免费试听按钮被点击");
            },
        },
        computed: {},
    }
</script>

<style lang="less">
    #app {
        background-color: #ffffff;
    }

    .examClass .van-popup {
        border-radius: 1.5rem;
        overflow: hidden;
    }
    .clock {
        margin: 0 2%;
        width: 96%;
        border-radius: 1rem;
        padding: 1.5rem;
        color: #ffffff;
        background-image: linear-gradient(-225deg, #5D9FFF 0%, #B8DCFF 48%, #6BBBFF 100%);
    }
    .clock  .van-cell__title span {
        color: #f2f53f;
        padding: 0 0.5rem;
        font-size: 2.4rem;
    }

    .question-subject-tab011 {
        padding: 17.5rem 0.5rem 0.5rem 0.5rem;
        background-color: #ffffff;
    }

    .imgBox {
        position: fixed;
        top: 6rem;
    }

    .imgBox .van-image__img {
        padding: 0.5rem 0rem 0.5rem 0rem;
        border-radius: 0.8rem;
    }

    // .question-subject-tab .subject-tab .van-tabs__wrap {
    //     position: fixed;
    //     top: 6rem;
    //     // z-index: 99;
    //     width: 100%;
    // }

    .question-subject-tab011 .subject-tab .van-tabs__line {
        bottom: 0.5rem !important;
        left: 0 !important;
        z-index: 1;
        width: 3.2rem !important;
        height: 0.5rem !important;
        border-radius: 2rem !important;
    }

    .question-subject-tab011 .subject-tab .van-tab .van-tab__text {
        padding: 0 0.6rem;
    }

    .question-subject-tab011 .subject-tab .van-tab--active .van-tab__text {
        font-size: 2.2rem !important;
    }

    // .question-subject-tab .subject-tab .van-tabs__content {
    //   margin: 1rem 0;
    // }

    .question-exam011 {
        margin: 1.5rem 2% !important;
        padding: 2.4rem 1rem 2rem 1rem !important;
        box-shadow: 0 0 0.5rem rgb(219, 219, 219);
        border-radius: 0.8rem !important;
        width: 96% !important;
    }
    .question-exam011 .examTypeBtn {
        position: absolute;
        top: 0;
        left: 0;
    }

    .question-exam011 .left-icon01 {
        font-size: 4.6rem !important;
        padding: 0rem 1rem 0rem 0rem !important;
        background: #ffffff;
        z-index: 2;
    }

    .question-exam011 .left-icon {
        font-size: 2.6rem !important;
        padding: 1rem 1rem 1rem 0rem !important;
        background: #ffffff;
        z-index: 2;
    }

    .question-exam011 .right-icon {
        font-size: 3rem !important;
        // color: #1989fa;
        padding: 0 0.3rem 0 0rem !important;
    }

    .question-exam011 .van-cell__title {
        font-size: 1.8rem;
        // color: #969799;
    }

    // .question-exam011 .van-cell__title span {
    //     color: #646566;
    // }

    .question-exam011 .van-cell__label {
        line-height: 2.4rem !important;
        font-size: 1.8rem !important;
        padding-top: 0.6rem;
    }

    .question-child {
        margin-left: 3rem;
    }

    .question-child .vertical-line {
        height: 100%;
        width: 0.1rem;
        background-color: #e7e9ed;
        position: absolute;
        left: 2.3rem;
    }

    .question-progress {
        float: left;
        margin: 0.8rem 1rem 0 0 !important;
        display: flex;
        justify-content: center;
    }

    .question-progress .van-progress__pivot {
        display: none;
    }

    .question-exam011-chapter {
        font-weight: 600 !important;
        font-size: 2.1rem;
    }

    .question-exam011-collapse .van-collapse-item {
        padding: 0rem 1rem 0rem 3.5rem !important;
    }

    .question-exam011-collapse .vertical-line {
        height: 100%;
        width: 0.1rem;
        background-color: #e7e9ed;
        position: absolute;
        left: 1.3rem;
    }

    .question-exam011-collapse .van-collapse-item .van-collapse-item__title {
        align-items: center;
        padding: 1.5rem 0 !important;
    }

    .question-exam011-collapse .van-collapse-item .van-collapse-item__title:active {
        background-color: #ffffff;
    }

    .question-exam011-collapse .van-collapse-item .question-exam011-list {
        // line-height: 6rem;
        display: flex;
        align-items: center;
        padding-right: 1rem;
    }

    .question-exam011-collapse .van-collapse-item .question-exam011-list .left-icon {
        font-size: 2.6rem !important;
        padding: 1rem 1rem 1rem 0rem !important;
        background: #ffffff;
        z-index: 2;
    }

    .question-exam011-collapse .van-collapse-item .right-icon {
        font-size: 3rem !important;
        padding: 1.5rem !important;
        background: #ffffff;
        z-index: 2;
    }

    .question-exam011-collapse .van-collapse-item .question-exam011-list .label {
        font-size: 1.8rem;
        color: #969799;
    }

    .question-exam011-collapse .van-collapse-item .van-icon-arrow {
        line-height: 6rem;
        display: flex;
        align-items: center;
        font-weight: 600;
    }


    .question-exam011-collapse::after {
        border-width: 0 !important;
    }

    .van-collapse-item__title::after {
        display: none !important;
    }

    .question-exam011-collapse .van-collapse-item .question-exam011-children {
        padding: 1.5rem 0 !important;
    }

    .question-exam011-collapse .van-collapse-item .question-exam011-children .left-icon {
        font-size: 2.6rem !important;
        padding: 1rem 1rem 1rem 0rem !important;
        background: #ffffff;
        z-index: 2;
    }

    .question-exam011-collapse .van-collapse-item .question-exam011-children .vertical-line {
        height: 100%;
        width: 0.1rem;
        background-color: #e7e9ed;
        position: absolute;
        left: 1.21rem;
    }

    .question-exam011-collapse .van-collapse-item .question-exam011-children .van-cell__label {
        line-height: 2.4rem !important;
        font-size: 1.8rem !important;
        padding-top: 1rem;
    }

    .question-exam011-collapse .van-collapse-item .question-exam011-children::after {
        border-width: 0 !important;
    }

    .question-content1 {
        border-radius: 1rem;
    }

    .question-content1 .question-content-popup {
        padding: 2rem 1.5rem;
        border-radius: 1rem;
        font-size: 2rem;
        line-height: 3rem;
        text-align: center;
    }

    .question-content1 .question-content-popup .van-icon {
        padding: 1rem 1.5rem;
        font-size: 3.5rem;
        line-height: 3rem;
    }
    .rightDropdown .van-dropdown-menu__bar {
      height: 4rem !important;
    }
    .rightDropdown .van-dropdown-menu__bar .van-dropdown-menu__title {
      padding: 0 1.8rem 0 .5rem;
      color: #323233;
      font-size: 2rem;
      line-height: 3rem;
    }
    .rightDropdown .van-dropdown-menu__bar .van-dropdown-menu__title::after {
      position: absolute;
      top: 20%;
      right: 0rem;
      margin-top: 0rem;
      border: 0.6rem solid;
      border-color: transparent transparent #adb1b4 #bcbec0;
      content: '';
    }
    .rightDropdown .van-dropdown-item__content {
      padding-top: 1rem;
    }
    .rightDropdown .van-dropdown-item .van-dropdown-item__option {
        padding: 0 1.5rem;
        align-items: center;
    }
    .rightDropdown .van-dropdown-item {
        z-index: 100;
    }
    .login-modal {
        width: 100%;
        height: 110%;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 99;
        bottom: -10rem;

        .login-form {
            width: 90%;
            background-color: #fff;
            padding-bottom: .2rem;
            position: absolute;
            top: 10%;
            left: 50%;
            margin-left: -45%;
            box-sizing: border-box;
            border-radius: 1rem;
        }
        .loginTitle{
            font-size: 2.4rem;
            height: 6rem;
            line-height: 6rem;
            text-align: center;
            margin: 1rem 0 2rem 0;
        }

        .close-modal {
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, 0.5);
        }
    }
    .yichuTag {
        display: grid;
        align-content: center;
    }
    .yichuTag .van-tag {
        font-size: 1.4rem !important;
    }


</style>




