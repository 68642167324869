<template>

<v-touch v-on:swipeleft="getCurrentTest('1')"  v-on:swiperight="getCurrentTest('-1')" :swipe-options="{ direction: 'horizontal'}" style="touch-action: pan-y!important;">
  <div class="zxks_dt">
    <!-- 导航栏 -->
    <comnavBar :Title="Time" :backType="'1'" @jisuanqiBtn="jisuanqiBtn" @onClickLeft="onClickLeft" @onClickRight="onClickRight"></comnavBar>
    
      <!-- 答题的内容 -->
      <div class="zxks_dt_content">
        <!-- <div class="comRadio_top"> -->
        <van-cell center class="comRadio_top">
          <template #title>
            <span v-if="currentTest.type == '2'">单选题</span>
            <span v-if="currentTest.type == '3'">多选题</span>
            <span v-if="currentTest.type == '6'">配伍选择题</span>
            <span v-if="currentTest.type == '1'">判断题</span>
            <span v-if="currentTest.type == '4'">简答题</span>
            <span v-if="currentTest.type == '5'">综合分析题</span>
          </template>
          <template #right-icon>
            <div class="countDown">
              <span v-if="currentTest.collection" @click="collection(currentTest.id,2)"><van-icon name="star" color="#ffd21e" size="2.2rem" style="margin:0 1rem" /></span>
              <span v-else @click="collection(currentTest.id,1)"><van-icon name="star-o" color="#1989fa" size="2.2rem" style="margin:0 1rem" /></span>
              <span style="margin:0 1rem;color:#CCCCCC">|</span>
              <span>{{ currentIndex + 1 }}</span>
              <span style="margin:0 0.6rem">/</span>
              <span style="color: #969799;">{{ $store.state.zxksList.length }}</span>
            </div>
          </template>
        </van-cell>

        <!-- </div> -->
        <div class="optionContent">
          <!-- 单选题 -->
          <comRadio :type="'0'" v-if="currentTest.type == '2'" :obj="currentTest.type == '2' ? currentTest : ''">
            <!-- {{currentTest.title}} -->
          </comRadio>
          <!-- 多选题 -->
          <comCheckbox :type="'0'" v-if="currentTest.type == '3'" :obj="currentTest.type == '3' ? currentTest : ''">
          </comCheckbox>
          <!-- 配伍选择题-单选 -->
          <comDataRadio :type="'0'" :typeTest="currentTest.typeTest" v-if="currentTest.type=='6'" :obj="currentTest.type == '6' ? currentTest : ''">
          </comDataRadio>
          <!-- 配伍选择题-多选 -->
          <comDataCheckbox :type="'0'" :typeTest="currentTest.typeTest" v-if="currentTest.type=='6'" :obj="currentTest.type == '6' ? currentTest : ''">
          </comDataCheckbox>
          <!-- 综合分析题-单选 -->
          <comDataRadio :type="'0'" :typeTest="currentTest.typeTest" v-if="currentTest.type=='5'" :obj="currentTest.type == '5' ? currentTest : ''">
          </comDataRadio>
          <!-- 综合分析题-多选 -->
          <comDataCheckbox :type="'0'" :typeTest="currentTest.typeTest" v-if="currentTest.type=='5'" :obj="currentTest.type == '5' ? currentTest : ''">
          </comDataCheckbox>
          <!-- 判断题 -->
          <comJudge :type="'0'" v-if="currentTest.type == '1'" :obj="currentTest.type == '1' ? currentTest : ''">
          </comJudge>
          <!-- 简答题 -->
          <comshortAnswer :type="'0'" v-if="currentTest.type == '4'" :obj="currentTest.type == '4' ? currentTest : ''">
          </comshortAnswer>
        </div>

        <div class="comRadio_tip">
          <!-- <van-icon name="info-o" color="#1989fa" size="2rem" />
          <div class="tip_words" @click="cktsFun()">查看提示</div> -->
          <van-button round center type="warning" size="mini" icon="question-o" @click="cktsFun()">我要提问</van-button>
        </div>
        
      </div>

      <!-- <van-submit-bar /> -->
    <!-- 切换试题区域 -->
    <div class="zxks_dt_btn">
      <van-row gutter="15">
        <van-col span="5">
          <van-button plain 
          @click="getCurrentTest('-1')" 
          :disabled="currentIndex == 0 ?true:false"
          color="#ffffff"
          icon="https://cdnoss.ksyt.com.cn/wxImages/icon_toleft.png"
          >
          </van-button>
        </van-col>
        <van-col span="7">
          <van-button type="info" @click="testIndexListFun()">答题卡</van-button>
        </van-col>
        <van-col span="7">
          <van-button type="info" @click="submitFun()">交卷</van-button>
        </van-col>
        <van-col span="5">
          <van-button plain 
          @click="getCurrentTest('1')" 
          :disabled="currentIndex == $store.state.zxksList.length - 1 ?true:false"  
          color="#ffffff"       
          icon="https://cdnoss.ksyt.com.cn/wxImages/icon_toright.png"
          ></van-button>
        </van-col>
      </van-row>

      <!-- <div>
        <van-button type="warning" plain @click="getCurrentTest('-1')" v-if="currentIndex !== 0">上一题</van-button>
      </div>
      <div>
        <van-button type="warning" plain @click="getCurrentTest('1')">下一题</van-button>
      </div>
      <div>
        <van-button type="warning" v-if="currentIndex == $store.state.zxksList.length - 1" @click="submitFun()">提交
        </van-button>
      </div> -->
    </div>
    <!-- 弹出层（查看提示） -->
    <van-popup round v-model="showTip" position="bottom" :style="{ height: '50%', width: '100%' }">
      <comZxksTip @cancelMask="cancelMask" :tip="currentTest.tip" :title="'我要提问'" :type="'tw'" :obj="currentTest" :commodityId="commodity_id" :testPaperId="testPaperId" @answerQuestion="answerQuestion"></comZxksTip>
    </van-popup>

    <!-- 弹出层（考试结束）时间到强制交卷 -->
    <van-popup class="poptip" round v-model="showEndTip" :close-on-click-overlay="false" :style="{ height: '35%', width: '80%' }">
      <comKnow @okTestTip="okTestTip" :tip="'考试时间已到，将强制提交做题结果！！'" :testPaperId="testPaperId" examinationType="1"></comKnow>
    </van-popup>

    <!-- 弹出层（提交） -->
    <van-popup class="poptip" round v-model="showIsSubmit" :close-on-click-overlay="false" :style="{ height: '35%', width: '80%' }">
      <comOkCancel :tip="tipWord" @cancelTestSubmit="cancelTestSubmit" :tipLeft="tipLeft" :tipRight="tipRight" :testPaperId="testPaperId" examinationType="1">
      </comOkCancel>
    </van-popup>

    <!-- 弹出层（计算器） -->
    <van-popup class="xzstpop" round v-model="showIsJisuanqi" closeable close-icon="cross" position="bottom":style="{ height: '80%', width: '100%' }">
      <div style="width: 100%;height: 100%;">
          <iframe style="width: 100%; height: 100%;" src="https://admin.ksyt.com.cn/index/calculator/calculator" allowfullscreen="allowfullscreen" frameborder="0" data-id="1"></iframe>
      </div>
    </van-popup>

    <!-- 弹出层（选择试题） -->
    <van-popup v-model="showXZST" closeable close-icon="cross" position="bottom" :style="{ height: '65%', width: '100%' }" class="xzstpop">
      <div class="xzstDiv" style="overflow-y:scroll;height: 100%;">
          <van-cell center class="xzstTitle">
            <template #title>
              <div class="custom-title">
              <div class="custom-tag"><van-tag color="#969799"></van-tag><span>已答</span></div>
              <div class="custom-tag"><van-tag plain color="#969799"></van-tag><span>未答</span></div>
              <!-- <div class="custom-tag"><van-tag type="success"></van-tag><span>做对</span></div>
              <div class="custom-tag"><van-tag type="danger"></van-tag><span>做错</span></div> -->
              </div>
              <div class="custom-label">点击下面题号即可跳转到该题哦！</div>
            </template>
            <!-- <template #right-icon>
              <van-icon name="https://fastly.jsdelivr.net/npm/@vant/assets/icon-demo.png" />
            </template> -->
          </van-cell>
<!--        <div class="xzstContent">-->
<!--          <div class="xzstContent_btn_no" :class="(currentIndex + 1) == item ? 'xzstContent_btn_ok' : ($store.state.zxksList[item-1].answer? 'xzstContent_btn_selected' : 'xzstContent_btn_no')"-->
<!--            v-for="(item, index) in $store.state.zxksList.length" :key="item" @click="updateTestIndexFun(item)">-->
<!--            <span :class="$store.state.zxksList[item-1].answer ? 'xzstContent_btn_selected' :  'xzstContent_btn_blank'">{{ item }}</span>-->
<!--          </div>-->
<!--        </div>-->

        <div class="xzstContent">
          <div :class="(currentIndex + 1) == item ? 'xzstContent_btn_ok' : ($store.state.zxksList[item-1].answer ? 'xzstContent_btn_selected' : 'xzstContent_btn_no')"
               v-for="(item, index) in $store.state.zxksList.length" :key="item" @click="updateTestIndexFun(item)">{{ item }}</div>
        </div>


      </div>
    </van-popup>

    <!-- 弹出层（试题答完的情况反馈） -->
    <van-popup class="resultPop" v-model="showSTQKFK" position="right" :style="{ height: '100%', width: '100%' }">
      <zxksKsdtqk :testPaperAnswerId="testPaperAnswerId" :testPaperId="testPaperId" :type="coursewareId ? 3 : 1" :title="title" :commodityId="commodity_id" :url="examUlr" :examType="exam_type" @againQuestion="againQuestion"></zxksKsdtqk>
    </van-popup>

  </div>
</v-touch>
  
</template>
 
<script>
  var url = '';
// 导航栏
import comnavBar from '@/components/com_navBar'
// 查看提示弹框
import comZxksTip from '@/components/com_zxksTip'
// 考试结束弹框
import comKnow from '@/components/com_know'
// 考试结束弹框
import comOkCancel from '@/components/com_ok_cancel'
// 考试结束答题情况弹框 答题结果
import zxksKsdtqk from '@/modules/zxks_ksdtqk'
// 单选题
import comRadio from '@/components/com_radio'
// 多选题
import comCheckbox from '@/components/com_checkbox'
// 配伍选择题-单选
import comDataRadio from '@/components/com_data_radio'
// 配伍选择题-多选
import comDataCheckbox from '@/components/com_data_checkbox'
// 判断题
import comJudge from '@/components/com_judge'
// 简答题
import comshortAnswer from '@/components/com_shortAnswer'
import {questionsList, testcollect, testPaperPracticeQuestionPost, testPaperPracticeQuestionPostClass} from "@/request/api";
import {Toast} from "vant";
export default {
  beforeRouteEnter(to, from, next) {
    // console.log(to,from);
    url = from.path
    next();
  },
  components: {
    comnavBar,
    comRadio,
    comCheckbox,
    comDataRadio,
    comDataCheckbox,
    comJudge,
    comshortAnswer,
    comZxksTip,
    comKnow,
    comOkCancel,
    zxksKsdtqk
  },
  data() {
    return {
      isAnswer:false,
      // 提交的属于哪个类型   1:返回的弹框  2:提交的弹框
      currentType: '',
      // 是否提交
      showIsSubmit: false,
      // 是否显示计算器
      showIsJisuanqi: false,
      // 提交的提示信息
      tipWord: '',
      // 左面按钮
      tipLeft: '',
      // 右面面按钮
      tipRight: '',
      // 当前考试的时长
      times: '',
      // 剩余时间
      Time: '',
      // 考试总时长
      totalTime: '',
      // 展示提示框
      showTip: false,
      // 导航的标题
      Title: '在线考试',
      // Title: Time,
      // 试题列表
      testList: [],
      // 当前试题
      currentTest: {},
      // 当前试题的索引
      currentIndex: 0,
      // 提示考试结束
      showEndTip: false,
      // 选择试题列表
      showXZST: false,
      // 试题答完之后的情况反馈
      showSTQKFK: false,
      //试卷id
      testPaperId: '',
      questionList: [],
      testPaperAnswerId:'',
      title: '',
      question_time:'00:00:00',
      commodity_id: '',
      coursewareId: '',
      test_paper_answer_id: '',
      exam_type:'',
      examUlr: url
    }
  },
  watch: {},
  created() {
    // 获取试题
    // this.getData()
    this.title = this.$route.query.title
    this.testPaperId = this.$route.query.id;
    this.commodity_id = this.$route.query.commodity_id;
    this.coursewareId = this.$route.query.coursewareId;
    this.test_paper_answer_id = this.$route.query.test_paper_answer_id;
    this.exam_type = this.$route.query.exam_type;
    this.getQuestionsList();
  },
  //1、挂载完成后，判断浏览器是否支持popstate
  mounted() {
    if (window.history && window.history.pushState) {
      history.pushState(null, null, document.URL);
      window.addEventListener('popstate', this.goBack, false);
    }
  },
  //页面销毁时，取消监听。否则其他vue路由页面也会被监听
  destroyed(){
    window.removeEventListener('popstate', this.goBack, false);
  },
  methods: {
    
    //3、将监听操作写在methods里面，removeEventListener取消监听内容必须跟开启监听保持一致，所以函数拿到methods里面写
    goBack(){
      // this.$router.replace({path: '/'});
      this.currentType = '1'
      this.showIsSubmit = true
      this.tipWord = '如退出当前考试，系统不会提交做题结果!!!'
      this.tipLeft = '退出'
      this.tipRight = '继续作答'
      
    },
    jisuanqiBtn(){
      this.showIsJisuanqi = true
    },
    //倒计时
    countDownTime() {
      this.times = this.times * 60
      this.timeDown()
      this.times++
      this.time = setInterval(() => {
        this.timeDown()
        this.times++
      }, 1000)
    },
    // 返回(强行退出当前考试)
    onClickLeft() {
      this.currentType = '1'
      this.showIsSubmit = true
      this.tipWord = '如退出当前考试，系统不会提交做题结果!!!'
      this.tipLeft = '退出'
      this.tipRight = '继续作答'
    },
    
    // 显示设置
    onClickRight() {
      // this.$emit('onClickRight')
      this.showPopover = !this.showPopover;
    },

    // 关闭提交框
    cancelTestSubmit(data,id) {
      console.log(this.times);
      console.log(data);
      console.log(this.currentType);
      if(id) {
        this.testPaperAnswerId = id;
      }
      console.log(id);
      // data   0:取消   1：确定
      // 点击返回的弹框
      if (this.currentType == '1') {
        if (data == '0') {
          this.$router.go(-2)
          console.log('返回按钮')
        } else {
          this.showIsSubmit = false
        }
      }
      // 点击提交的弹框
      if (this.currentType == '2') {
        if (data == '0') {
          this.showIsSubmit = false
        } else {
          this.getTestPaperPost();
          // this.showSTQKFK = true
        }
      }
    },
    // 时间到考试结束弹框
    okTestTip(data) {
      // this.currentType = '2'
      // this.showEndTip = false
      // data   0:取消   1：确定
      // 点击返回的弹框
      // if (this.currentType == '1') {
      //   if (data == '0') {
      //     this.$router.go(-1)
      //   } else {
      //     this.showEndTip = false
      //   }
      // }
      // 点击提交的弹框
      if (this.currentType == '2') {
        if (data == '0') {
          //取消提交
          this.showEndTip = false
        } else {
          //提交
          this.showSTQKFK = true
        }
      }
    },
    // 提交
    submitFun() {
      this.currentType = '2'
      this.showIsSubmit = true
      this.tipWord = '是否提交您的试卷!!!'
      this.tipLeft = '暂不提交'
      this.tipRight = '确认交卷'
    },
    //提交试卷
    getTestPaperPost() {
        let questionList = this.$store.state.zxksList;
        let testPaperId = this.testPaperId;
        let question_time = this.times;
        let testPaperAnswerId = this.test_paper_answer_id;
        const formData = []
        questionList.forEach(item => {
          if (item.type == 4) {
            if (item.answer !== '') {
              formData.push({
                id: item.id,
                answer: item.answer
              })
            }
          } else {
            if ((item.answer) ?? '' !== '') {
              formData.push({
                id: item.id,
                answer: item.answer
              })
            }
          }
        })
        console.log(formData);
        if(formData.length <= 0) {
          Toast.fail('请先作答后再提交');
          return false;
        }
        let that = this;
        //判断 如果有科目是随堂练习
        if(that.coursewareId) {
          testPaperPracticeQuestionPostClass({
            test_paper_id:testPaperId,
            question_time,
            type: '4',
            examination_type: 1,
            question_data: formData,
            coursewareId:that.coursewareId
          })
                  .then(res => {
                    if (res.data.code == 1) {
                      console.log(res.data);
                      Toast.success('提交成功');
                      that.testPaperAnswerId = res.data.data.id;
                      that.showSTQKFK = true
                      // this.$emit('cancelTestSubmit', val,id) //调用上一级
                    }
                  })
                  .catch(err => {
                    console.log(err);
                    Toast.fail('操作错误');
                  })
        } else {
          testPaperPracticeQuestionPost({
            test_paper_id:testPaperId,
            question_time,
            type: '4',
            examination_type: 1,
            question_data: formData,
            testPaperAnswerId
          })
                  .then(res => {
                    if (res.data.code == 1) {
                      console.log(res.data);
                      Toast.success('提交成功');
                      that.testPaperAnswerId = res.data.data.id;
                      that.showSTQKFK = true
                      // this.$emit('cancelTestSubmit', val,id) //调用上一级
                    }
                  })
                  .catch(err => {
                    console.log(err);
                    Toast.fail('操作错误');
                  })
        }

    },
    // 切换试题索引
    updateTestIndexFun(val) {
      this.currentIndex = val - 1
      this.currentTest = this.$store.state.zxksList[this.currentIndex]
      this.showXZST = false
      this.$store.state.currentIndex = this.currentIndex; //设置选项使用
    },
    // 展示试题列表
    testIndexListFun() {
      this.showXZST = true
    },
    
    // 将时间转化格式
    timeDown() {
      let leftTime = this.times
      let m = this.formate(parseInt(leftTime / 60))
      let s = this.formate(parseInt(leftTime % 60))
      this.Time = `用时：${m}分${s}秒` // 需要修改时间样式的话 ，比如需要什么30分钟倒计时，就只保留分和秒
    },
    formate(time) {
      if (time >= 10) {
        return time
      } else {
        return `0${time}`
      }
    },
    // 查看提示
    cktsFun() {
      this.showTip = true
    },
    // 取消查看提示的弹框
    cancelMask() {
      this.showTip = false
    },
    // 点击切换试题
    getCurrentTest(val) {
      if (val == '1') {
        if(this.$store.state.zxksList[this.currentIndex+1]) {
          this.currentIndex++
          this.currentTest = this.$store.state.zxksList[this.currentIndex]
        }
      } else {
        if(this.$store.state.zxksList[this.currentIndex-1]) {
          this.currentIndex--
          this.currentTest = this.$store.state.zxksList[this.currentIndex]
        }
      }
      this.$store.state.currentIndex = this.currentIndex; //设置选项使用
      console.log(this.currentTest)
    },
    getQuestionsList() {
      let that = this;
      let testPaperId = that.testPaperId;
      let test_paper_answer_id = that.test_paper_answer_id;
      questionsList({testPaperId,test_paper_answer_id})
              .then(res => {
                if (res.data.code == 1) {
                  that.questionList = res.data.data.questions;
                  that.testList = res.data.data.questions;
                  that.$store.state.zxksList = res.data.data.questions;
                  that.currentTest = that.$store.state.zxksList[that.currentIndex]
                  that.$store.state.currentIndex = that.currentIndex; //设置选项使用
                  that.countDownTime();
                }
              })
              .catch(err => {
                console.log("error");
                console.log(err);
              })
    },
    /**
     * 收藏
     */
    collection(id,type) {
      let that = this;
      let testPaperId = that.testPaperId;
      testcollect({testid:id,type,testPaperId})
              .then(res => {
                if (res.data.code == 1) {
                  this.currentTest.collection = type == 1 ? true : false;
                  Toast.success(res.data.msg);
                }
              })
              .catch(err => {
                Toast.fail('收藏操作错误');
              })
    },
    //重新做题
    againQuestion() {
      // console.log('返回上一级组件');
      this.$router.go(0);
    },
    answerQuestion() {
      this.showTip = false;
    }
  }
}
</script>
 
<style scoped>

.zxks_dt {
  padding: 6rem 1rem 8rem 1rem;
  height: 100%;
  background: #ffffff;
}
.xzstpop{
  overflow: hidden;
}
.zxks_dt_content {
  width: 100%;
  background: #ffffff;
  /* padding: 0 1rem; */
}

.zxks_dt_content .optionContent {
  padding-top: 6rem;
}

.zxks_dt_btn {
  width: 100%;
  height: 4.5rem;
  display: flex;
  position: fixed;
  bottom: 0;
  left: 0;
  padding: 1rem 0 1.5rem 0;
  background-color: #ffffff;
}

.zxks_dt_btn .van-row {
  width: 100%;
  padding: 0 2rem;
}

.zxks_dt_btn>div /deep/ .van-button {
  width: 100%;
  font-size: 4rem;
  /* height: 10vw; */
  /* line-height: 10vw; */
}
.zxks_dt_btn>div /deep/ .van-button .van-icon__image {
  font-size: 4rem;
}

/* 标题 */
.comRadio_top {
  height: 3rem !important;
  /* border-bottom: 0.1rem solid #f7f7f7; */
  /* display: flex;
  justify-content: center; */
  position: fixed;
  left: 2rem;
  padding-right: 4rem;
  z-index: 99;
}

.comRadio_top::after {
  border-width: 0 !important;
}

/* .comRadio /deep/ .van-popup {
  overflow-y: hidden;
} */

.van-popup--bottom.van-popup--round {
  border-radius: 1.5rem 1.5rem 0 0 !important;
}
.van-popup--center.van-popup--round {
  border-radius: 1.5rem !important;
}

.comRadio_top .van-cell__title {
  height: 2.2rem;
  line-height: 2.2rem;
  text-align: left;
  border-left: 0.5rem solid #1989fa;
  padding-left: 1rem;
  font-weight: bold;
  font-size: 2.2rem;
}

.comRadio_top .countDown {
  height: 2rem;
  font-size: 2rem;
  line-height: 2.2rem;
  text-align: left;
  margin-left: 1rem;
  padding: 0 1rem;
  text-align: right;
  display: flex;
  justify-content: center;
}

.comRadio_top .right-icon {
  width: 2.2rem;
  height: 2.2rem;
  font-size: 2.2rem;
  line-height: 2.2rem;
  margin-left: 0.5rem;
  margin-right: 1.5rem;
}

.comRadio_top .right-icon img {
  width: 2.2rem !important;
  height: 2.2rem;
  font-size: 2.2rem;
}

/* 提示 */
.comRadio_tip {
  width: auto;
  display: flex;
  padding: 2rem 1rem;
  justify-content: flex-start;
  align-items: center;
}

.comRadio_tip .tip_words {
  /* width: 20%; */
  color: #777777;
  font-size: 2rem;
  padding-left: 0.2rem;
}

.comRadio_tip img {
  width: 2.2rem;
  height: 2.2rem;
}

/* 选择试题 */
.xzstTitle {
  height: auto;
  line-height: 8rem;
  padding: 0 1.5rem;
  width: 100%;
  background-color: #ffffff;
  position: fixed;
  /* padding */
}
.xzstTitle .van-cell__title {
  padding: 0.5rem;
  /* height: 8rem; */
}
.xzstTitle .van-cell__title .custom-title{
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.xzstTitle .van-cell__title .custom-title .custom-tag{
  color: #656466;
  padding: 1rem 1rem 0rem 0.5rem;
  font-size: 1.8rem;
  display: flex;
  /* justify-content: center; */
  align-items: center;
}
.xzstTitle .van-cell__title .custom-title .van-tag {
  height: 1.8rem;
  width: 1.8rem;
  padding: 0 !important;
  margin-right: 0.3rem;
  border-radius: 0.3rem;
}
.xzstTitle .van-cell__title .custom-label {
  font-size: 1.6rem;
  color: #656466;
  padding: 0 1rem 0 0.5rem;
}


.xzstContent {
  height: auto;
  text-align: center;
  display: flex;
  align-content: flex-start;
  flex-flow: row wrap;
  padding: 9rem 1rem 1rem 1rem;
  /* overflow-y: scroll; */
  /* touch-action: pan-y!important; */
}

.xzstContent>div {
  border-radius: 0.5rem;
  font-size: 2rem;
  flex: 0 0 12%;
  padding-top: calc(3.5% - 0rem);
  padding-bottom: calc(3.5% - 0rem);
  margin: 2%;

}

.xzstContent_btn_no {
  border: 0.1rem solid #969799;
  color: #656466;
}

.xzstContent_btn_ok {
  border: 0.1rem solid #1989fa;
  color: #1989fa;
}

.xzstContent_btn_selected {
  background-color: #969799;
  color: #ffffff;
  border: 0.1rem solid #969799;
}

.xzstContent_btn_blank {
  border: 0.1rem solid #969799;
  color: #656466;
}
.poptip {
  overflow-y: hidden;
}
</style>
