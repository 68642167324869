<template>
    <div class="comRadio">
        <!-- 在线答题(练习模式) -->
        <div v-if="type=='0'" class="comRadio_div">
            <div class="comRadio_title">
                {{$store.state.currentIndex+1}}.
                <span style="margin-left:0.5rem" v-html="obj.title"></span>
                <span class="comRadio_scoreClass">（{{obj.score}}分）</span>
                <!-- 显示图片 -->
                <span v-for="(item,index) in obj.title_img" :key="index" v-show="item">
                    <van-image
                        v-if="item"
                        class="title_img"
                        width="20rem"
                        height="10rem"
                        fit="cover"
                        :src="item"
                        @click="getImg(obj.title_img, index)"
                    />
                </span>
                <!-- 显示视频 -->
                <span v-html="obj.html_video">

                </span>
<!--                <span v-for="(item,index) in obj.title_video" :key="'video-'+index">-->
<!--          <video-->
<!--                  id="myVideo"-->
<!--                  class="video-js"-->
<!--                  controls-->
<!--                  preload="auto"-->
<!--                  :poster="obj.videoAnalysisPoster"-->
<!--                  :style="contentStyleObj"-->
<!--          >-->
<!--            <source :src="item" type="video/mp4">-->
<!--          </video>-->
<!--        </span>-->

            </div>
            <!-- 未选试题  v-if="radioVal<'0'"-->
            <div class="comRadio_option" v-if="obj.answer==''">
                <div
                        v-for="(item,index) in obj.tmList"
                        :key="index"
                        :class="obj.answer == item.key?'inputClassOk':'inputClass'"
                        @click="clickHandle(item,index,'0')"
                >
                    <span class="option_key">{{item.key}}</span>
                    <span class="option_value">{{item.value}}
            <van-image
                    v-for="(item,index) in item.tmImg"
                    :key="index"
                    class="title_img"
                    fit="fill"
                    :src="item"
            />
          </span>
                </div>
            </div>
            <!-- 已选试题  v-if="radioVal<'0'"-->
            <div class="comRadio_option" v-else-if="(obj.answer!='')">
                <div
                        v-for="(item,index) in obj.tmList"
                        :key="index"
                        :class="obj.answer == item.key?'inputClassOk':'inputClass'"
                >
                    <!-- 选择了正确答案 -->
                    <span v-if="(obj.correct_answer==obj.answer)" :class="obj.correct_answer==item.key?'option_key_ok':'option_key'">{{item.key==obj.answer?'':item.key}}</span>
                    <!-- 选择了错误答案 -->
                    <span v-else-if="(obj.correct_answer!=obj.answer)" :class="item.key===obj.correct_answer ?'option_key_ok':(item.key===obj.answer?'option_key_no':'option_key')">{{(item.key==obj.correct_answer ?'':(item.key==obj.answer ?'':item.key))}}</span>
                    <span class="option_value">{{item.value}}
            <van-image
                    v-for="(item,index) in item.tmImg"
                    :key="index"
                    class="title_img"
                    fit="fill"
                    :src="item"
            />
          </span>
                </div>
            </div>
            <!-- 单选有选项之后显示答案解析 -->
            <div v-if="obj.answer!='' || obj.answer == 'null'">
                <div class="comRadio_answer">
                    <div class="answer_box">
                        <span class="my_answer">参考答案：{{obj.correct_answer}}</span>
                        <span :class="(obj.correct_answer===obj.answer?'reference_answer':'reference_answer_correct')">我的答案：{{obj.answer ? obj.answer : '无'}}</span>
                    </div>
                    <div class="knowledge_box">
                        <span class="knowledge">考察知识点：</span>
                        <span class="knowledge-text">{{obj.knowledge}}</span>
                    </div>
                    <div class="accuracy_box">
                        <span class="accuracy">全网正确率：</span>
                        <span class="accuracy-text">{{obj.accuracy}}</span>
                        <van-button v-if="obj.videoAnalysis != ''" round class="video-analysis" icon="play-circle-o"
                                    type="info" size="mini" @click="onShowVideo">{{obj.videoState?'隐藏视频':'视频讲解'}}
                        </van-button>
                    </div>
                </div>
                <div v-if="obj.videoAnalysis != ''">
                    <div class="comRadio_video_analysis" v-show="obj.videoState">
                        <van-cell center title="讲师视频讲解">
                        </van-cell>
                        <div class="video_box" ref="attrRef">
                            <video
                                    id="myVideo"
                                    class="video-js"
                                    controls
                                    preload="auto"
                                    :poster="obj.videoAnalysisPoster"
                                    :style="contentStyleObj"
                            >
                                <source :src="obj.videoAnalysis" type="video/mp4">
                            </video>
                        </div>
                    </div>
                </div>
                <div class="comRadio_analysis">
                    <van-cell center use-label-slot title="解析">
                        <!-- <template #right-icon>
                            <van-button round center type="warning" size="mini" icon="question-o" @click="onShowShare">我要提问</van-button>
                        </template> -->
                    </van-cell>
                    <van-tag color="#f6f7f9" text-color="#646566" size="large"><span v-html="obj.analysis"></span></van-tag>
                </div>
            </div>
        </div>

        <!-- 在线答题(考试模式) -->
        <div v-if="type=='1'" class="comRadio_div">
            <div class="comRadio_title">
                {{$store.state.currentIndex+1}}.
<!--                <span style="margin-left:0.5rem">{{obj.title}}</span>-->
                <span style="margin-left:0.5rem" v-html="obj.title"></span>
                <span class="comRadio_scoreClass">（{{obj.score}}分）</span>
                <!-- 显示图片 -->
                <span v-for="(item,index) in obj.title_img" :key="index" v-show="item">
                    <van-image
                            v-if="item"
                            class="title_img"
                            width="20rem"
                            height="10rem"
                            fit="cover"
                            :src="item"
                            @click="getImg(obj.title_img, index)"
                    />
                </span>
                <!-- 显示视频 -->
                <span v-html="obj.html_video">

                </span>
<!--                <span v-for="(item,index) in obj.title_video" :key="'video-'+index">-->
<!--          <video-->
<!--                  id="myVideo"-->
<!--                  class="video-js"-->
<!--                  controls-->
<!--                  preload="auto"-->
<!--                  :poster="obj.videoAnalysisPoster"-->
<!--                  :style="contentStyleObj"-->
<!--          >-->
<!--            <source :src="item" type="video/mp4">-->
<!--          </video>-->
<!--        </span>-->

            </div>


            <div class="comRadio_option">
                <div
                        v-for="(item,index) in obj.tmList"
                        :key="index"
                        :class="obj.answer == item.key?'inputClassOk':'inputClass'"
                        @click="clickHandle(item,index,'1')"
                >
                    <!-- <div
                      v-for="(item,index) in obj.tmList"
                      :key="index"
                      :class="item.state?'inputClassOk':'inputClass'"
                      @click="changeFun(item,'0')"
                    > -->
                    <!-- <input
                      :id="item.id"
                      type="radio"
                      :value="item.id"
                      :checked="item.id===radioVal?true:false"
                    > -->
                    <span class="option_key">{{item.key}}</span>
                    <span class="option_value">{{item.value}}
                    <van-image
                            v-for="(item,index) in item.tmImg"
                            :key="index"
                            class="title_img"
                            fit="fill"
                            :src="item"
                    />
          </span>
                </div>
            </div>


        </div>
    </div>
</template>

<script>
    import {ImagePreview} from 'vant';

    export default {
        components: {ImagePreview},
        props: ['obj', 'type'],
        data() {
            return {
                // 选中的答案
                radioVal: '-1',
                radioValKey: '',
                clickIndex: -1,
                showVideo: true,
                contentStyleObj: {
                    height: '',
                    width: ''
                },
                questionInfo: []
                // images: '',
                // item:'',
            }
        },
        mounted() {
            this.contentStyleObj.height = this.$refs.attrRef.offsetWidth * 9 / 16 + 'px ' + '!important';
            this.contentStyleObj.width = window.getComputedStyle(this.$refs.attrRef).width + ' ' + '!important';
        },
        watch: {
            obj: {
                handler(val) {
                    //拿到后台返回的正确选项显示，单选默认选中
                    this.radioVal = val.answer
                    //  this.$emit('getRadioVal', { tmId: this.questionId, radioValue: val })
                },
                deep: true,
                immediate: true
            }
        },
        created() {
            console.log(this.obj);
            this.questionInfo = this.$store.state.zxksList[this.$store.state.currentIndex]
        },
        methods: {
            getImg(images, index) {
                // console.log(images)
                console.log(images)
                ImagePreview({
                    images: images, //需要预览的图片 URL 数组
                    showIndex: true, //是否显示页码
                    loop: false, //是否开启循环播放
                    startPosition: index //图片预览起始位置索引
                })
            },
            onShowVideo() {
                this.obj.videoState = !this.obj.videoState;
                this.showVideo = !this.showVideo
            },
            onShowShare() {
            },
            // 单选【可取消】【3】 选择答案，需要把答案赋值到数组 type:0练习；1考试
            /**
             *
             * @param val 当前选项值 选项、内容等
             * @param index 选项下标
             * @param type 模式 0练习模式，1考试模式
             */
            clickHandle(val, index, type) {
                console.log(this.$store.state.currentIndex);
                const currentTest = this.$store.state.zxksList[this.$store.state.currentIndex]
                currentTest.answer = val.key;
                if (currentTest.correct_answer == val.key) {
                    currentTest.isRight = true;
                } else {
                    currentTest.isRight = false
                }
                this.$store.state.zxksList[this.$store.state.currentIndex] = currentTest;
                this.obj = currentTest;


                // console.log(val, index, type)
                // console.log(this.radioVal)
                // 模式 0练习模式，1考试模式
                console.log(this.radioVal + 'xuanzhong11')
                if (type === '0') {
                    if (this.clickIndex != index) {
                        this.clickIndex = index
                    }
                    this.radioVal = val.id
                    this.radioValKey = val.key
                }
                console.log(this.radioVal + 'xuanzhong')
                if (type === '1') {
                    if (this.clickIndex != index) {
                        this.clickIndex = index
                    } else {
                        this.clickIndex = null
                    }
                    this.radioVal = val.id
                }
            },
        }
    }
</script>

<style scoped>
    /* 标题 */
    .comRadio_div .comRadio_title {
        /* margin-top: 1rem; */
        width: auto;
        text-align: left;
        padding: 0 1rem;
        font-size: 2.2rem;
        line-height: 3.2rem;
    }

    .comRadio_div .comRadio_scoreClass {
        color: #969799;
    }

    /* 处理单选样式 */
    .comRadio_div .comRadio_option input {
        width: 2rem;
        height: 2rem;
    }

    .comRadio_div .comRadio_option {
        margin: 1rem 1rem 2rem 1rem;
    }

    .comRadio_div .inputClass {
        position: relative;
        height: auto;
        padding: 1rem;
        /* margin-left: 15px; */
        text-align: left;
        margin-top: 1.5rem;
        background: #f6f7f9;
        border: 0.1rem solid #f6f7f9;
        border-radius: 0.5rem;
        font-size: 2rem;
        line-height: 3rem;
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }

    .comRadio_div .inputClass .option_key {
        border: 0.1rem solid #cccccc;
        border-radius: 100%;
        height: 2rem;
        width: 2rem;
        padding: 0.4rem;
        position: absolute;
        left: 1rem;
        display: flex;
        justify-content: center;
        align-items: center;
        /* display: none; */
    }

    .comRadio_div .inputClass .option_key_ok {
        background-image: url('https://cdnoss.ksyt.com.cn/wxImages/icon-correct.png');
        background-size: 100%;
        color: #07c160;
        border-radius: 100%;
        height: 2.2rem;
        width: 2.2rem;
        padding: 0.5rem;
        position: absolute;
        left: 1rem;
        display: flex;
        justify-content: center;
        align-items: center;
        /* display: none; */
    }

    .comRadio_div .inputClass .option_key_no {
        background-image: url('https://cdnoss.ksyt.com.cn/wxImages/icon-error.png');
        background-size: 100%;
        color: #ee0a24;
        border-radius: 100%;
        height: 2.2rem;
        width: 2.2rem;
        padding: 0.5rem;
        position: absolute;
        left: 1rem;
        display: flex;
        justify-content: center;
        align-items: center;
        /* display: none; */
    }

    .comRadio_div .inputClass .option_value {
        padding-left: 4rem;
        display: flex;
        flex-direction: column;
    }

    .comRadio_div .inputClassOk {
        position: relative;
        height: auto;
        padding: 1rem;
        text-align: left;
        margin-top: 1.5rem;
        background: #f6f7f9;
        border: 0.1rem solid #f6f7f9;
        color: #1989fa;
        border-radius: 0.5rem;
        font-size: 2rem;
        line-height: 3rem;
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }

    .comRadio_div .inputClassOk .option_key_ok {
        background-image: url('https://cdnoss.ksyt.com.cn/wxImages/icon-correct.png');
        background-size: 100%;
        color: #07c160;
        border-radius: 100%;
        height: 2.2rem;
        width: 2.2rem;
        padding: 0.5rem;
        position: absolute;
        left: 1rem;
        display: flex;
        justify-content: center;
        align-items: center;
        /* display: none; */
    }

    .comRadio_div .inputClassOk .option_key_no {
        background-image: url('https://cdnoss.ksyt.com.cn/wxImages/icon-error.png');
        background-size: 100%;
        color: #ee0a24;
        border-radius: 100%;
        height: 2.2rem;
        width: 2.2rem;
        padding: 0.5rem;
        position: absolute;
        left: 1rem;
        display: flex;
        justify-content: center;
        align-items: center;
        /* display: none; */
    }

    .comRadio_div .inputClassOk .option_key {
        border: 0.1rem solid #1989fa;
        background-color: #1989fa;
        color: #ffffff;
        border-radius: 100%;
        height: 2rem;
        width: 2rem;
        padding: 0.4rem;
        position: absolute;
        left: 1rem;
        display: flex;
        justify-content: center;
        align-items: center;
        /* display: none; */
    }

    .comRadio_div .inputClassOk .option_value {
        padding-left: 4rem;
        display: flex;
        justify-content: flex-start;
    }

    .comRadio_div .inputClass input[type='radio'] {
        width: 2rem;
        height: 2rem;
        opacity: 0;
    }

    /* /另一种样式 （预览试题）*/
    .commentRadio input {
        width: 4.5vw;
        height: 4.5vw;
    }

    .commentRadio .inputClass {
        position: relative;
        line-height: 6vw;
        margin-left: 15px;
        text-align: left;
    }

    .commentRadio .inputClass input[type='radio'] {
        width: 20px;
        height: 20px;
        opacity: 0;
    }

    .commentRadio .inputClass > label {
        position: absolute;
        left: -2px;
        top: 9px;
        width: 15px;
        height: 15px;
        border-radius: 50%;
        border: 1px solid #999;
    }

    /*设置选中的input的样式*/
    /* + 是兄弟选择器,获取选中后的label元素*/
    .commentRadio .inputClass input:checked + label {
        background-color: #f24d4b;
        border: 1px solid #f24d4b;
    }

    .commentRadio .inputClass input:checked + label::after {
        position: absolute;
        content: '';
        width: 5px;
        height: 10px;
        top: 0;
        left: 4.1px;
        border: 2px solid #fff;
        border-top: none;
        border-left: none;
        transform: rotate(45deg);
    }


</style>
