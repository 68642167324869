<template>
    <div>
        <van-nav-bar class="learning-title" fixed left-arrow>
            <template #left>
                <van-button color="#ffffff" icon="arrow-down" @click="onBack">配套习题</van-button>
            </template>
            <!-- <template #right>
                <van-grid>
                    <van-grid-item icon="chat-o" text="听课帮助"/>
                </van-grid>
            </template> -->
        </van-nav-bar>
        <div v-if="testPaperList.length>0">
            <van-tabs sticky animated swipeable class="courseList" color="#1989fa">
                <van-tab :title="item.cate_name" v-for="(item,index) in testPaperList">
                    <div class="courseCell" v-for="(item2,index2) in item.data" :key="index2">
                        <van-tag round color="#1989fa" text-color="#ffffff"><font>{{index2+1}}</font>{{item2.cate_name}}</van-tag>
                        <div v-for="(item3,index3) in item2.data" :key="index3">
                            <van-cell center is-link @click="goExam(item3.id,item3.coursewareId,item3.p_id,item3.title)">
                                <template #icon>
                                    <van-icon name="circle"/>
                                </template>
                                <template #right-icon>
                                    <van-icon name="edit"/>
                                    <!-- 下面是已做试题按钮 -->
                                    <!-- <van-icon name="chart-trending-o"/> -->
                                </template>
                                <template #title>
                                    <div class="van-cell__title">
                                        {{item3.title}}
                                    </div>
                                </template>
                                <template #label>
                                    <div class="van-cell__label">共{{item3.question_num}}题，{{item3.count}}人做过</div>
                                </template>
                            </van-cell>
                        </div>
                    </div>
                </van-tab>
            </van-tabs>
        </div>
        <!-- 无课程的时候显示 -->
        <van-empty
                v-else
                description="当期课程暂未更新配套习题"
                image="https://cdnoss.ksyt.com.cn/wxImages/no-course.png"
                image-size="24rem">
        </van-empty>
    </div>
</template>

<script>
    import {commodityTestPaperList2} from "@/request/api";
    import {Toast} from "vant";
    let formName;
    let paramsData;
    // let commodityId;
    export default {
        beforeRouteEnter(to, from, next) {
            let that = this;
            // console.log(to);
            // console.log(from);
            // console.log(next);
            // if (!to.params || !to.params.id) {
            //     next({
            //         path: from.name,
            //         query: {} // 将跳转的路由path作为参数，登录成功后跳转到该路由
            //     })
            // }
            formName = from.name;
            paramsData = from.params;
            next();
        },
        metaInfo() {
            return {
                title: this.title + '-学习', //设置页面title
            }
        },
        data() {
            return {
                title: this.getBranchCompany(),
                active: '',
                item: '',
                courseData: [],
                courseFreeData: [],
                lastStudy: [],
                courseShow: true,
                testPaperList: [],
                commodity_id: '',
                course_id: '',
                cate_id: '',
                coursewareId: ''
            }
        },
        methods: {
            getTestPaperList() {
                let that = this;
                let id = that.commodity_id;
                let course_id = that.course_id;
                let cate_id = that.cate_id;
                commodityTestPaperList2({id,cate_id,course_id})
                    .then(res => {
                        if (res.data.code == 1) {
                            that.testPaperList = res.data.data;
                            console.log(that.testPaperList)
                        } else {
                            Toast.fail(res.data.msg);
                        }
                    })
                    .catch(err => {
                        console.log("error");
                        console.log(err);
                    })
            },
            lastStudyDetail() {
                const {id, commodityId, coursewareId} = this.lastStudy;
                console.log(id);
                console.log(commodityId);
                console.log(coursewareId);
                this.$router.push({
                    name: 'MyCourseDetail',
                    params: {
                        id,
                        commodityId,
                        coursewareId
                    }
                })
            },
            onBack() {
                
                history.back();
                // console.log(this.$router);
                // this.$router.push('/learning');
                // this.$router.go(-1)
                // this.$router.push({
                //     name: paramsData?'MyCourseDetail': 'Learning',
                //     params: paramsData
                // })
                window.android.back();
            },
            goExam(id,coursewareId,type,title) {
                this.$router.push({
                    name: 'zxksDTLX',
                    query: {
                        title,
                        id,
                        commodity_id:this.commodity_id,
                        coursewareId
                    },
                    params: {
                        title,
                        id,
                        commodity_id:this.commodity_id,
                        coursewareId
                    }
                })
            }
        },
        created() {
            this.commodity_id = this.$route.query.id
            this.course_id = this.$route.query.course_id
            this.cate_id = this.$route.query.cate_id
            this.coursewareId = this.$route.query.coursewareId
            this.getTestPaperList();
        }
    }
</script>

<style lang="less">
    .learning-title .van-nav-bar__content .van-nav-bar__left .van-button__text {
        font-size: 2.2rem !important;
        font-weight: 400 !important;
        color: #333333 !important;
    }

    .learning-title .van-nav-bar__content .van-nav-bar__left .van-icon {
        font-size: 2.2rem !important;
        padding-top: 1.5rem;
        padding-bottom: 1.5rem;
        margin-right: 2rem;
        font-weight: 600;
        color: #333333;
        -moz-transform: rotate(90deg);
        -webkit-transform: rotate(90deg);
    }

    .learning-title .van-nav-bar__content .van-nav-bar__right .van-icon {
        font-size: 2.8rem !important;
    }

    .learning-title .van-nav-bar__content .van-nav-bar__right .van-grid-item__text {
        font-size: 1.4rem !important;
    }

    .learning-title .van-nav-bar__content .van-nav-bar__right {
        padding: 0 0.5rem !important;
    }

    .learning-title .van-nav-bar__content .van-nav-bar__right .van-grid-item__content {
        width: 6rem !important;
    }

    .courseList {
        padding: 0rem 0 8rem 0;
    }

    .courseList .van-tabs__line {
        bottom: 0.5rem;
        left: 0;
        z-index: 0;
        width: 3.2rem;
        height: 0.5rem;
        border-radius: 2rem;
        position: absolute;
    }

    .courseList .van-sticky {
        padding-top: 6rem;
    }

    .courseList .van-tab--active .van-tab__text {
        font-size: 2rem;
    }

    .courseCell {
        margin: 1rem;
        border-radius: 1rem;
        padding: 1.5rem;
        background-color: #ffffff;
    }

    .courseCell .van-tag {
        padding: 0.4rem 1rem 0.4rem 0.5rem !important;
        font-size: 1.6rem !important;
    }

    .courseCell .van-tag font {
        color: #1989fa;
        font-size: 1.6rem;
        width: 2rem;
        height: 2rem;
        margin-right: 0.3rem;
        background-color: #ffffff;
        border-radius: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .courseCell .van-cell {
        padding: 1rem 0;
    }

    .courseCell .van-cell .van-icon {
        margin-right: 0.8rem;
        font-size: 1.8rem;
        color: #646566;
    }

    .courseCell .van-cell .van-icon-edit {
        margin-right: 0rem;
        font-size: 3.2rem;
        color: #646566;
    }

    .courseCell .van-cell .van-icon-chart-trending-o {
        margin-right: 0rem;
        font-size: 3.2rem;
        color: #1989fa;
    }
    .courseCell .van-cell .van-cell__title {
        font-size: 1.8rem;
        line-height: 3rem;
    }

    .courseCell .van-cell .van-cell__label {
        font-size: 1.6rem;
        line-height: 3rem;
    }

    .lastCell {
        padding: 1rem 1.5rem;
        background-color: #1989fa;
        position: fixed;
        bottom: 0;
    }

    .lastCell .van-icon {
        font-size: 6rem;
        margin-right: 1rem;
    }

    .lastCell .van-cell__title {
        color: #ffffff;
        line-height: 3rem;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .lastCell .van-cell__label {
        line-height: 3rem;
    }

    .lastCell .van-cell__label .van-tag {
        font-size: 1.6rem !important;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        border-radius: 0.3rem;
    }

</style>
